import React, { ReactNode } from 'react';

/**
 * Possible menu item variants
 */
export enum MenuItemVariants {
  basic = 'basic',
  destructive = 'destructive',
  delimiter = 'delimiter',
}

/**
 * Config for a menu item
 */
export interface MenuItemType {
  key?: React.Key;
  href?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  variant?: MenuItemVariants;
  content?: ReactNode;
  renderContent?: () => ReactNode;
  onPress?: () => void;
}
