export const MASLOV_ROUTES_TREE = {
  root: '/',
  companyChunk: `/:companyId`,

  Auth: {
    login: '/login',
    companySelector: '/select-company',
  },

  Integrator: {
    root: `integrator`,
    blueprintsUrl: '/integrator/blueprints',
    blueprintDetails: 'blueprints/:id',
    blueprintDetailsUrl: `/integrator/blueprints/:id?runType=:runType`,

    blueprintEdit: 'blueprints/edit/:id',
    blueprintEditUrl: '/integrator/blueprints/edit/:id',

    masterBlueprintEdit: 'blueprints/edit/master',
    masterBlueprintEditUrl: '/integrator/blueprints/edit/master',
  },

  User: {
    root: `user`,
    productionCalendar: 'production-calendar',
    userProductionCalendar: 'user/production-calendar',
    productionCalendarUrl: '/user/production-calendar',

    userUrl: '/user/',
    cow: 'cows/:cowId/*',

    incidents: 'incidents',
    incidentsUrl: '/user/incidents',

    plannedInjections: 'planned-injections',
    plannedInjectionsUrl: '/user/planned-injections',
    userPlannedInjections: 'user/planned-injections',

    analytics: {
      index: 'analytics',
      livestockForecast: 'livestock-forecast',
      herd: 'herd',
      herdUrl: '../herd',

      reproduction: 'reproduction',
      reproductionUrl: '/user/analytics/reproduction',
      reproductionBackUrl: '../reproduction',

      monitor: 'monitor',

      customReports: 'custom-reports',
      customReportsUrl: '/user/analytics/custom-reports',
      customReportId: ':customReportId',
      customReportLaunch: ':launchHashId',
      customReportLaunchDetail: 'detail',

      milking: 'milking',

      metric: 'metric/:metric',
      reproductionTable: ':reproductionTableUrl',
      metricUrl: '../metric/:metric',
    },

    entities: {
      index: 'entities',
      milkingParlors: 'milking-parlors',
      milkingParlorsUrl: '/user/entities/milking-parlors',
      milkingsScheduleFarmId: ':farmId',
      milkingsSchedule: 'schedule',
      milkingScheduleUrl: '/user/entities/milking-parlors/:farmId/schedule',
      milkingParlorInterval: 'intervals',
      milkingParlorIntervalIntervalId: ':intervalId',
      milkingParlorIntervalUrl: 'intervals/:intervalId',
      milkings: 'milkings',
      semenDoses: 'semenDoses',
    },

    analyticsUrl: '/user/analytics',

    charts: '',
    chartsUrl: '/user/charts',

    entitiesUrl: '/user/entities',

    herd: {
      index: 'herd',
      cows: 'cows',
      moveCowsKeys: 'moveCowsKeys',
      moveCowsKeysUrl: '/user/herd/cows/moveCowsKeys',
      bulls: 'bulls',
      penGroups: 'penGroups',
    },

    herdUrl: '/user/herd',

    blueprint: 'blueprint/:id',
    blueprintUrl: '/user/blueprint/:id',

    events: {
      index: 'events',
      eventsUrl: '/user/events',

      all: 'all',
      commands: 'commands',
      cowEvents: 'cow-events',
    },

    eventsUrl: '/user/events',
  },
} as const;
