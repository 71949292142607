import React, { useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import { VitalityFilter } from '@graphql-types';
import R from 'ramda';

import { Badge } from '~/shared/components/Badge';
import { DateInput, DateInputThemes } from '~/shared/components/DateInput';
import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Input, InputVariants } from '~/shared/components/Input';
import { Loader } from '~/shared/components/Loader';
import { SelectThemes } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { HALF_SPACE } from '~/shared/constants';
import { useDebouncedValue } from '~/shared/hooks/useDebouncedValue';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { useCowsPaginatedQuery } from '~/entities/cows/hooks';
import { useFarmSelect } from '~/entities/farms';
import { usePenGroupSelect } from '~/entities/penGroups';

import { SizeVariants } from '~/styles/__generated__/token-variants';
import TOKENS from '~/styles/__generated__/tokens.json';

import { MoveCowCard } from '../../../../components';
import { CowsCopyKeyFragment } from '../../../../gql/fragments/CowsCopyKey.graphql';
import { IdentifierMappingsFormType } from '../../hooks/useIdentifierMappingsForm';
import stepsStyles from '../../steps.module.scss';
import styles from './index.module.scss';

interface Props
  extends Pick<
    UseModalStepFormInterface<IdentifierMappingsFormType>,
    'formProps'
  > {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * cows from CowsCopyKeyFragment
   */
  cows: CowsCopyKeyFragment['cows'];
}

const MAX_COWS_TO_LOAD = 1000;
const VALIDATE_IDENTIFIERS_DEBOUNCE_MS = 500;

type IdentifierTargetFieldName =
  `identifierMappings.${number}.targetIdentifier`;

const getIdentifierTargetFieldName = (index: number) =>
  `identifierMappings.${index}.targetIdentifier` as const;

export const IdentifierMappingsForm: React.FC<Props> = ({
  className,
  formProps,
  cows,
}) => {
  const { formContext } = formProps;
  const { fields: identifierMappings } = useFieldArray({
    control: formContext.control,
    name: 'identifierMappings',
  });

  const farmID = formContext.watch('farmID');

  const remoteIdentifierDuplicates = useRef<Set<number>>(new Set());

  const [identifiersToCheck, setIdentifiersToCheck] = useState<
    Record<IdentifierTargetFieldName, number>
  >(() =>
    cows.reduce(
      (acc, { identifier }, index) => {
        acc[getIdentifierTargetFieldName(index)] = identifier;
        return acc;
      },
      {} as Record<IdentifierTargetFieldName, number>
    )
  );

  const validateIdentifiersForDuplicate = () => {
    formContext
      .getValues('identifierMappings')
      .forEach(({ targetIdentifier }, index) => {
        const otherIdentifierMappings = formContext
          .getValues('identifierMappings')
          .filter(
            identifierMapping =>
              identifierMapping.targetIdentifier !== targetIdentifier
          );
        const hasLocalDuplicates =
          otherIdentifierMappings.length + 1 !== identifierMappings.length;

        const fieldName = getIdentifierTargetFieldName(index);
        if (
          remoteIdentifierDuplicates.current.has(targetIdentifier) ||
          hasLocalDuplicates
        ) {
          formContext.setError(fieldName, {
            message: 'Такой номер уже используется',
          });
        } else {
          formContext.clearErrors(fieldName);
        }
      });
  };

  const debouncedIdentifiersToCheck = useDebouncedValue(
    identifiersToCheck,
    VALIDATE_IDENTIFIERS_DEBOUNCE_MS
  );
  const debouncedIdentifiersToCheckItems = Array.from(
    new Set(Object.values(debouncedIdentifiersToCheck))
  );

  const { fetchMore } = useCowsPaginatedQuery({
    variables: {
      first: MAX_COWS_TO_LOAD,
      vitalityFilter: VitalityFilter.All,
      identifiers: debouncedIdentifiersToCheckItems,
      farmIDs: [farmID],
    },
    skip: !debouncedIdentifiersToCheckItems.length || !farmID,
    onCompleted: async ({ cows: { edges: cowEdges, pageInfo } }) => {
      const hasMore = pageInfo.hasNextPage;
      cowEdges.forEach(({ node: { identifier } }) => {
        remoteIdentifierDuplicates.current.add(identifier);
      });

      if (hasMore) {
        await fetchMore();
      } else {
        setIdentifiersToCheck(prev => {
          const fetchedIdentifiers = (
            Object.keys(prev) as IdentifierTargetFieldName[]
          ).filter(fieldName =>
            debouncedIdentifiersToCheckItems.includes(
              identifiersToCheck[fieldName]
            )
          );
          return R.omit(fetchedIdentifiers, prev);
        });
        validateIdentifiersForDuplicate();
      }
    },
  });

  const { renderSelectElement: renderFarmsSelectElement } = useFarmSelect({
    selectProps: {
      label: 'Ферма для новых животных',
      name: 'farmID',
      theme: SelectThemes.light,
      className: stepsStyles.input,
      onValueChange() {
        setIdentifiersToCheck(
          formContext.getValues('identifierMappings').reduce(
            (acc, { targetIdentifier }, index) => {
              acc[getIdentifierTargetFieldName(index)] = targetIdentifier;
              return acc;
            },
            {} as Record<IdentifierTargetFieldName, number>
          )
        );
        remoteIdentifierDuplicates.current = new Set();
      },
    },
  });

  const { renderSelectElement: renderPenGroupIdSelectElement } =
    usePenGroupSelect({
      selectProps: {
        name: 'penGroupID',
        label: 'Группа для новых животных',
        theme: SelectThemes.light,
        className: stepsStyles.input,
      },
    });

  const getIdentifierStatusIcon = (isValid: boolean, isLoading: boolean) => {
    if (isLoading) {
      return <Loader size={SizeVariants.size24} />;
    }
    return isValid ? (
      <Icon
        {...{
          variant: IconVariants.checkCircleFilled,
          color: TOKENS.colorSuccessDefault,
        }}
      />
    ) : (
      <Icon
        {...{
          variant: IconVariants.cancelCircleFilled,
          color: TOKENS.colorErrorDefault,
        }}
      />
    );
  };
  return (
    <Form
      {...{
        className,
        ...formProps,
        onSubmit: async e => {
          await formContext.trigger();
          validateIdentifiersForDuplicate();
          if (
            Object.hasOwn(formContext.formState.errors, 'identifierMappings') ||
            !R.isEmpty(identifiersToCheck)
          ) {
            e.preventDefault();
            return;
          }

          formProps.onSubmit?.(e);
        },
      }}
    >
      <Typography
        {...{
          tag: 'div',
          variant: TypographyVariants.bodyMediumStrong,
          className: 'mb-16',
        }}
      >
        Информация о животных
      </Typography>
      <div className="flex gap-16 items-start mb-24">
        <DateInput
          {...{
            name: 'arriveDate',
            label: 'Дата прибытия',
            theme: DateInputThemes.light,
            className: stepsStyles.input,
          }}
        />
        {renderFarmsSelectElement()}
        {renderPenGroupIdSelectElement()}
      </div>
      <Typography
        {...{
          tag: 'div',
          className: stepsStyles.descriptionBlock,
          variant: TypographyVariants.bodySmall,
        }}
      >
        Присвойте животным рабочие номера. По умолчанию указаны номера, которые
        использовались на предыдущей ферме
      </Typography>
      <div className="flex gap-8 mb-12">
        <Typography variant={TypographyVariants.bodySmallStrong}>
          Список животных
        </Typography>
        <Badge isPill>{identifierMappings?.length ?? 0}</Badge>
      </div>
      <div className="flex flex-col gap-12">
        {identifierMappings.map(
          ({ originIdentifier, targetIdentifier }, index) => {
            const hasIdentifierMappingError =
              !!formContext.formState.errors.identifierMappings?.[index];
            const identifierFieldName = getIdentifierTargetFieldName(index);

            return (
              <MoveCowCard
                {...{
                  key: originIdentifier,
                  index: index + 1,
                  contentItems: [
                    <div
                      key={originIdentifier}
                      className={styles.cowIdTextBlock}
                    >{`№${HALF_SPACE}${originIdentifier}`}</div>,
                    <Icon
                      {...{
                        key: IconVariants.arrowUp,
                        variant: IconVariants.arrowUp,
                        rotate: RotateVariants.right,
                        className: 'text-soft',
                      }}
                    />,
                    <Input
                      {...{
                        key: identifierFieldName,
                        name: identifierFieldName,
                        variant: InputVariants.int,
                        withRightFeedback: true,
                        value: targetIdentifier,
                        className: stepsStyles.input,
                        withFormat: false,
                        onValueChange: newTargetIdentifier => {
                          if (!R.isNil(newTargetIdentifier)) {
                            setIdentifiersToCheck(prev => ({
                              ...prev,
                              [identifierFieldName]: newTargetIdentifier,
                            }));
                          }
                        },
                        addonAfter: getIdentifierStatusIcon(
                          !hasIdentifierMappingError,
                          identifierFieldName in identifiersToCheck
                        ),
                      }}
                    />,
                  ],
                }}
              />
            );
          }
        )}
      </div>
    </Form>
  );
};
