import React, { useEffect, useState } from 'react';

import R from 'ramda';

import { Typography, TypographyVariants } from '~/shared/components/Typography';

import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';
import { useArkaNavigation } from '~/services/navigation';
import { useNotifications } from '~/services/notifications';

import { useBlueprintInputs } from '~/entities/blueprintValues';
import { useCustomReportDetailedFromCacheOrQuery } from '~/entities/customReports';

import { BlueprintLaunchCard } from '../../components/BlueprintLaunchCard';
import { CustomReportLaunchResultFragment } from '../../gql/fragments/customReportLaunchResult.graphql';
import { getCustomReportLaunchPageUrl } from '../../helpers';
import { useLaunchCustomReport } from '../../hooks/useLaunchCustomReport';

export interface CustomReportSettingsModalProps
  extends InjectedModalProps<CustomReportSettingsModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Custom report id for setting up
   */
  customReportId: string;
  /**
   * If true, user is restarting a report, only affects texts
   */
  isRestart?: boolean;
}

const FORM_ID = 'CustomReportSettingsForm';

export const CustomReportSettingsModal: React.FC<
  CustomReportSettingsModalProps
> = ({ className, customReportId, isRestart = false, close }) => {
  const { sendSuccessToast } = useNotifications();

  const { fragment: customReport, isLoading: isCustomReportLoading } =
    useCustomReportDetailedFromCacheOrQuery(customReportId);

  const { navigate, getPathRelativeToCompany } = useArkaNavigation();

  const {
    blueprintInputs,
    getBlueprintValueInputByInputId,
    blueprintFormSchema,
    renderBlueprintInput,
  } = useBlueprintInputs(customReport?.blueprint.inputs);

  type CustomReportSettingsFormType = InferSchemaWithDefaults<
    typeof blueprintFormSchema
  >;
  type CustomReportSettingsFormTransformedType = InferValidatedSchema<
    typeof blueprintFormSchema
  >;

  const formContext = useForm<
    CustomReportSettingsFormType,
    CustomReportSettingsFormTransformedType
  >({
    schema: blueprintFormSchema,
    defaultValues: blueprintFormSchema.getDefault(),
  });

  const {
    abortControllerRef,
    isLaunchLoading,
    launchCustomReport,
    customReportLaunchResult,
    customReportLaunchErrors,
  } = useLaunchCustomReport({
    customReport,
  });

  const [isProceedingToReport, setProceedingToReport] = useState(false);

  const proceedToReport = (launchResult: CustomReportLaunchResultFragment) => {
    close();

    navigate(
      getPathRelativeToCompany(
        getCustomReportLaunchPageUrl(customReportId, launchResult.hashID)
      ),
      {
        state: launchResult,
      }
    );

    if (isRestart) {
      sendSuccessToast('Отчёт обновлён');
    }
  };

  const handleSubmit = (form: CustomReportSettingsFormTransformedType) => {
    if (!customReport) return;
    setProceedingToReport(true);

    const reportInputs = Object.entries(form).map(([inputID, inputValue]) => ({
      inputID,
      value: getBlueprintValueInputByInputId(inputID, inputValue),
    }));

    if (customReportLaunchResult && !formContext.formState.isDirty) {
      // If we already requested the launch result, just proceed
      proceedToReport(customReportLaunchResult);
    } else {
      launchCustomReport(reportInputs).then(launchResult => {
        // Reset form dirty state, so we know that current launch result correlates to inputs
        formContext.reset(formContext.getValues(), { keepValues: true });

        if (!launchResult) {
          setProceedingToReport(false);
          return;
        }

        proceedToReport(launchResult);
      });
    }
  };

  // Require explicit closing, if we've already entered some inputs,
  // or received launchResult, but it has inputs and not equal to initial
  const canCloseWithOverlay =
    !blueprintInputs.length ||
    (!formContext.formState.isDirty && !customReportLaunchResult) ||
    !!customReportLaunchResult;

  const isLoading = isLaunchLoading || isProceedingToReport;

  const shouldDisplayCard = isLoading || !!customReportLaunchErrors.length;
  const shouldDisplayInputs = !isLoading && !!blueprintInputs.length;

  // If we don't have inputs in the report, we should proceed automatically
  useEffect(() => {
    if (isCustomReportLoading) return;

    if (!blueprintInputs.length) {
      formContext.handleSubmit(handleSubmit)();
    }
  }, [isCustomReportLoading, blueprintInputs]);

  return (
    <Modal
      {...{
        className,
        title: isRestart ? 'Обновление отчёта' : 'Открытие отчёта',
        subtitle: customReport?.name,
        submitButtonProps: {
          form: FORM_ID,
          children: isRestart ? 'Обновить' : 'Открыть',
          isLoading,
        },
        onClose: () => {
          abortControllerRef.current?.abort();
        },
        renderButtons: isLoading ? R.always(null) : undefined,
        isLoading: isCustomReportLoading,
        isRequireExplicitClosing: !canCloseWithOverlay,
      }}
    >
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        {shouldDisplayCard && (
          <BlueprintLaunchCard
            {...{
              className: shouldDisplayInputs ? 'mb-24' : undefined,
              customReportLaunchErrors,
              isLoading,
            }}
          />
        )}
        {shouldDisplayInputs && (
          <div className="grid gap-16">
            <Typography variant={TypographyVariants.heading4}>
              {customReport?.blueprint.name}
            </Typography>
            {blueprintInputs.map(blueprintInput =>
              renderBlueprintInput(blueprintInput, { key: blueprintInput.id })
            )}
          </div>
        )}
      </Form>
    </Modal>
  );
};
