import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { BullFragmentDoc } from '../fragments/bull.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BullsQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  excludeIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type BullsQuery = { __typename?: 'Query', bulls: { __typename?: 'BullConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'BullEdge', cursor: string, node: { __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } }> } };


export const BullsDocument = gql`
    query bulls($search: String, $first: Int, $after: String, $excludeIDs: [ID!]) {
  bulls(first: $first, search: $search, after: $after, excludeIDs: $excludeIDs) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Bull
      }
    }
  }
}
    ${BullFragmentDoc}`;

/**
 * __useBullsQuery__
 *
 * To run a query within a React component, call `useBullsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBullsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBullsQuery({
 *   variables: {
 *      search: // value for 'search'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      excludeIDs: // value for 'excludeIDs'
 *   },
 * });
 */
export function useBullsQuery(baseOptions?: Apollo.QueryHookOptions<BullsQuery, BullsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BullsQuery, BullsQueryVariables>(BullsDocument, options);
      }
export function useBullsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BullsQuery, BullsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BullsQuery, BullsQueryVariables>(BullsDocument, options);
        }
export function useBullsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BullsQuery, BullsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BullsQuery, BullsQueryVariables>(BullsDocument, options);
        }
export type BullsQueryHookResult = ReturnType<typeof useBullsQuery>;
export type BullsLazyQueryHookResult = ReturnType<typeof useBullsLazyQuery>;
export type BullsSuspenseQueryHookResult = ReturnType<typeof useBullsSuspenseQuery>;
export type BullsQueryResult = Apollo.QueryResult<BullsQuery, BullsQueryVariables>;