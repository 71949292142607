import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { MonitorGroupFragmentDoc } from '../fragments/monitorGroup.graphql';
import { MonitorEntryFragmentDoc } from '../fragments/monitorEntry.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MonitorQueryVariables = Types.Exact<{
  since?: Types.InputMaybe<Types.Scalars['Date']['input']>;
  till?: Types.InputMaybe<Types.Scalars['Date']['input']>;
}>;


export type MonitorQuery = { __typename?: 'Query', monitor: { __typename?: 'MonitorConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'MonitorEdge', cursor: string, node: { __typename: 'MonitorGroup', id: string, name: string } | { __typename: 'MonitorEntry', id: string, name: string, target?: number | null, calculationMethod: Types.CalculationMethodEnum, monitorGroup: { __typename: 'MonitorGroup', id: string }, leftBlueprint: { __typename: 'Blueprint', id: string }, leftBlueprintSourceField?: { __typename: 'SourceField', id: string } | null, rightBlueprint?: { __typename: 'Blueprint', id: string } | null, values: Array<{ __typename: 'MonitorLaunchCalculatingValue', monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: string } } | { __typename: 'MonitorLaunchErrorValue', message: string, monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: string } } | { __typename: 'MonitorLaunchResultValue', value: number, monitorLaunch: { __typename: 'MonitorLaunch', id: string, happenedAt: string } }> } }> } };


export const MonitorDocument = gql`
    query monitor($since: Date, $till: Date) {
  monitor(since: $since, till: $till) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        __typename
        ... on MonitorGroup {
          ...MonitorGroup
        }
        ... on MonitorEntry {
          ...MonitorEntry
        }
      }
    }
  }
}
    ${MonitorGroupFragmentDoc}
${MonitorEntryFragmentDoc}`;

/**
 * __useMonitorQuery__
 *
 * To run a query within a React component, call `useMonitorQuery` and pass it any options that fit your needs.
 * When your component renders, `useMonitorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMonitorQuery({
 *   variables: {
 *      since: // value for 'since'
 *      till: // value for 'till'
 *   },
 * });
 */
export function useMonitorQuery(baseOptions?: Apollo.QueryHookOptions<MonitorQuery, MonitorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MonitorQuery, MonitorQueryVariables>(MonitorDocument, options);
      }
export function useMonitorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MonitorQuery, MonitorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MonitorQuery, MonitorQueryVariables>(MonitorDocument, options);
        }
export function useMonitorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MonitorQuery, MonitorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MonitorQuery, MonitorQueryVariables>(MonitorDocument, options);
        }
export type MonitorQueryHookResult = ReturnType<typeof useMonitorQuery>;
export type MonitorLazyQueryHookResult = ReturnType<typeof useMonitorLazyQuery>;
export type MonitorSuspenseQueryHookResult = ReturnType<typeof useMonitorSuspenseQuery>;
export type MonitorQueryResult = Apollo.QueryResult<MonitorQuery, MonitorQueryVariables>;