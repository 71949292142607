import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import clsx from 'clsx';

import {
  Button,
  ButtonThemes,
  ButtonVariants,
} from '~/shared/components/Button';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeRefs } from '~/shared/helpers/mergeProps';
import { normalizeValuesToArray } from '~/shared/helpers/normalize';
import { withOptionalFormController } from '~/shared/hocs/withOptionalFormController';
import { useControllableState } from '~/shared/hooks/useControllableState';
import { BaseFieldProps } from '~/shared/types/controls';

import { FileItem } from '../FileItem';
import styles from './index.module.scss';

type PossibleValueTypes = File | File[] | null | undefined;

interface Props
  extends Omit<DropzoneOptions, 'multi' | 'noClick' | 'noDrag' | 'onDrop'>,
    Omit<BaseFieldProps<PossibleValueTypes>, 'label' | 'labelProps'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, support multiple files
   */
  isMulti?: boolean;
}

const FilePickerInner = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,

      name,
      value: valueProp,
      defaultValue: defaultValueProp,

      isMulti,
      isDisabled,

      onValueChange,

      accept,

      ...dropzoneOptions
    },
    ref
  ) => {
    const value = normalizeValuesToArray(valueProp)?.filter(Boolean) ?? [];
    const defaultValue =
      normalizeValuesToArray(defaultValueProp)?.filter(Boolean) ?? [];

    const [innerValue, setInnerValue] = useControllableState(
      value,
      onValueChange,
      defaultValue
    );

    const handleDropFiles: DropzoneOptions['onDrop'] = acceptedFiles => {
      setInnerValue(acceptedFiles);
    };

    const handleDeleteFile = (index: number) => {
      setInnerValue(prev => prev.filter((_, i) => index !== i));
    };

    const { getRootProps, getInputProps, isDragActive, open, inputRef } =
      useDropzone({
        accept,
        disabled: isDisabled,
        noClick: true,
        multiple: isMulti,
        onDrop: handleDropFiles,
        ...dropzoneOptions,
      });

    const fileExtensions = Object.values(accept ?? {})
      .map(types => types.join(', '))
      .join(', ');

    if (innerValue.length) {
      return (
        <div className={className}>
          {innerValue.map((file, index) => (
            <FileItem
              {...{
                key: file.name,
                file,
                onDelete: () => handleDeleteFile(index),
              }}
            />
          ))}
        </div>
      );
    }

    return (
      <div
        {...{
          ...getRootProps({
            className: clsx(styles.root, className, {
              [styles.disabled]: isDisabled,
              [styles.dragActive]: isDragActive,
            }),
          }),
        }}
      >
        <input {...getInputProps({ name })} ref={mergeRefs(ref, inputRef)} />
        <Typography tag="div" variant={TypographyVariants.bodySmall}>
          Перетащите {fileExtensions} файл
        </Typography>
        <Typography tag="div" variant={TypographyVariants.bodySmall}>
          или
        </Typography>
        <Button
          {...{
            className: 'mt-12',
            isDisabled,
            theme: ButtonThemes.accent,
            variant: ButtonVariants.secondary,
            onPress: open,
          }}
        >
          Выберите файл
        </Button>
      </div>
    );
  }
);

export const FilePicker = withOptionalFormController<
  Props,
  PossibleValueTypes,
  File | File[] | null
>({
  defaultValue: ({ isMulti }) => (isMulti ? [] : null),
  convertValueFromComponentToForm: (value, { isMulti }) => {
    if (isMulti && !!value) return normalizeValuesToArray(value) as File[];
    if (Array.isArray(value)) return value[0];
    return null;
  },
})(FilePickerInner);
