import React, { useMemo } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';

import { useArkaNavigation } from '~/services/navigation';

import { getIntegratorRoutes, getUserRoutes } from '../../routing';
import { BaseLayout } from '../BaseLayout';

const NotFoundPage = React.lazy(() =>
  import('../../pages/NotFoundPage').then(component => ({
    default: component.NotFoundPage,
  }))
);

const SelectCompanyPage = React.lazy(() =>
  import('~/pages/selectCompany').then(component => ({
    default: component.SelectCompanyPage,
  }))
);

export const RequireAuthRoutes: React.FC = () => {
  const { getPathRelativeToCompany } = useArkaNavigation();

  const modulesRoutes = useMemo(() => {
    return {
      integrator: getIntegratorRoutes(),
      user: getUserRoutes(getPathRelativeToCompany),
    };
  }, [getPathRelativeToCompany]);

  return (
    <Routes>
      <Route
        path={MASLOV_ROUTES_TREE.root}
        element={
          <Navigate
            replace
            to={getPathRelativeToCompany(MASLOV_ROUTES_TREE.User.root)}
          />
        }
      />
      <Route
        path={MASLOV_ROUTES_TREE.Auth.companySelector}
        element={
          <BaseLayout withMenu={false}>
            <SelectCompanyPage />
          </BaseLayout>
        }
      />

      <Route path={MASLOV_ROUTES_TREE.companyChunk} element={<Outlet />}>
        {modulesRoutes.user}
        {modulesRoutes.integrator}
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};
