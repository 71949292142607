/**
 * All possible pen group attributes
 */
export enum PenGroupAttributeFields {
  isMilking = 'isMilking',
  isDry = 'isDry',
  isHospital = 'isHospital',
  isCalf = 'isCalf',
  isArtificialInsemination = 'isArtificialInsemination',
  isWithBull = 'isWithBull',
  isUser = 'isUser',
}

/**
 * Generic type for storing selected attributes
 */
export type PenGroupAttributeObject<T extends null | undefined | false> =
  Record<
    PenGroupAttributeFields,
    T extends null ? null | boolean : T extends undefined ? true : boolean
  >;

/**
 * Type for filters in Pen group table page
 */
export type PenGroupFilter = {
  attributes: PenGroupAttributeFields[];
  search: string;
};
