import React from 'react';

import clsx from 'clsx';

import {
  Button,
  ButtonProps,
  ButtonSizes,
  ButtonVariants,
} from '~/shared/components/Button';
import { mergeProps } from '~/shared/helpers/mergeProps';
import { useScrollIntoViewRef } from '~/shared/hooks/useScrollIntoViewRef';

import { MenuItem } from './components/MenuItem';
import styles from './index.module.scss';
import { MenuItemType, MenuItemVariants } from './types';

export interface MenuProps<ItemType extends MenuItemType = MenuItemType> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Items to render in the menu
   */
  items: ItemType[];
  /**
   * Called, when an item is pressed
   */
  onItemPress?: (item: ItemType, index: number) => void;
  /**
   * Action to change menu open state
   */
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * Index of an item, activated by arrow navigation
   */
  activeIndex?: number | null;
  /**
   * Additional props getter for implementing navigation
   */
  getItemProps?: (
    index: number,
    userProps?: React.HTMLProps<HTMLElement>
  ) => React.ComponentProps<'li'>;
  /**
   * If passed, an additional action button is rendered in the menu
   */
  listActionButtonProps?: Partial<Omit<ButtonProps, 'ref'>>;
}

export const Menu = <ItemType extends MenuItemType = MenuItemType>({
  className,
  items,
  onItemPress,
  setIsOpen,
  activeIndex,
  getItemProps,

  listActionButtonProps,
}: MenuProps<ItemType>) => {
  const selectedItemRef = useScrollIntoViewRef();

  return (
    <div className={clsx(styles.root, className)}>
      <ul className={styles.menu}>
        {items.map((item, index) => {
          const itemVariant = item.variant ?? MenuItemVariants.basic;

          if (itemVariant === MenuItemVariants.delimiter) {
            return (
              <hr
                {...{
                  key: index,
                  className: styles.delimiter,
                }}
              />
            );
          }

          return (
            <MenuItem<ItemType>
              {...{
                ref: item.isSelected ? selectedItemRef : undefined,
                key: `${item.key}__${index}`,
                className: clsx(
                  styles.item,
                  itemVariant === MenuItemVariants.destructive && 'text-error'
                ),
                item,
                onPress: i => onItemPress?.(i, index),
                isHighlighted: activeIndex === index,
                getItemProps: userProps =>
                  getItemProps?.(index, userProps) ?? userProps ?? {},
              }}
            />
          );
        })}
      </ul>
      {!!listActionButtonProps && (
        <div className={styles.additionalListItem}>
          <Button
            {...{
              variant: ButtonVariants.secondary,
              size: ButtonSizes.small24,
              ...mergeProps(listActionButtonProps, {
                onPress: () => setIsOpen?.(false),
              }),
            }}
          />
        </div>
      )}
    </div>
  );
};

export * from './types';
