import { useCallback, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';

import { AppLocalStorageKeys } from '~/shared/constants';

import { useMyUser } from '~/services/auth';
import { getNumberPartFromGlobalId, toGlobalId } from '~/services/gql';

/**
 * Fabric for making getter of the relative to company path with default companyId
 */
const makeGetPathRelativeToCompany =
  (defaultCompanyId?: string | null) =>
  (url: string, companyId = defaultCompanyId) => {
    if (!companyId) {
      return MASLOV_ROUTES_TREE.Auth.companySelector;
    }

    const slashDelimeter = url.startsWith('/') ? '' : '/';

    return `/${getNumberPartFromGlobalId(companyId)}${slashDelimeter}${url}`;
  };

/**
 * Getter for a router path with /:companyId/ param
 */
export type GetPathRelativeToCompany = ReturnType<
  typeof makeGetPathRelativeToCompany
>;

export const useArkaNavigation = () => {
  const navigate = useNavigate();

  // Router company id
  const companyIdRouteMatch = useMatch({
    path: `${MASLOV_ROUTES_TREE.companyChunk}/*`,
  });
  const companyIdParam = companyIdRouteMatch?.params.companyId;
  const routerCompanyId =
    companyIdParam && !Number.isNaN(+companyIdParam)
      ? companyIdParam
      : undefined;

  // Saved in storage company id
  const storageCompanyId = localStorage.getItem(
    AppLocalStorageKeys.selectedCompanyId
  );

  // Default company id from user's companies list
  const { myUser, isIntegratorByCompanyId } = useMyUser();

  const defaultCompanyId =
    myUser.companies.edges.length === 1
      ? myUser.companies.edges[0].node.id
      : undefined;

  // Update saved in storage company id, if we update route (router is our source of truth)
  useEffect(() => {
    if (routerCompanyId && routerCompanyId !== storageCompanyId) {
      localStorage.setItem(
        AppLocalStorageKeys.selectedCompanyId,
        routerCompanyId
      );
    }
  }, [routerCompanyId, storageCompanyId]);

  const selectedCompanyId = toGlobalId(
    routerCompanyId ?? storageCompanyId ?? defaultCompanyId,
    'company'
  );

  const selectedCompany = myUser.companies.edges.find(
    c => c.node.id === selectedCompanyId
  )?.node;

  const getPathRelativeToCompany = useCallback(
    makeGetPathRelativeToCompany(selectedCompanyId),
    [selectedCompanyId]
  );

  // Redirect to select company page, if company is not found by id
  useEffect(() => {
    if (!!selectedCompanyId && !selectedCompany) {
      navigate(MASLOV_ROUTES_TREE.Auth.companySelector);
    }
  }, [selectedCompanyId, selectedCompany]);

  const currentCompanyPath = companyIdRouteMatch?.params['*'];
  const selectCompany = useCallback(
    (companyId: string) => {
      navigate(
        `/${getNumberPartFromGlobalId(companyId)}/${currentCompanyPath}`
      );
    },
    [currentCompanyPath]
  );

  return {
    navigate,
    selectedCompanyId,
    selectedCompany,
    isIntegratorInSelectedCompany: isIntegratorByCompanyId(selectedCompanyId),
    getPathRelativeToCompany,
    selectCompany,
  };
};
