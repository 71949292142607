import React from 'react';

import R from 'ramda';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';

import { Avatar, AVATAR_COLOR_VARIANTS } from '~/shared/components/Avatar';
import { ContextMenu } from '~/shared/components/ContextMenu';
import { IconVariants } from '~/shared/components/Icon';

import { useMyUser } from '~/services/auth';
import { useArkaNavigation } from '~/services/navigation';

import { NavigationMenuSelect } from '../NavigationMenuSelect';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * If true, selector is rendered in collapsed mode
   */
  isMenuCollapsed: boolean;
}

export const CompanySelector: React.FC<Props> = ({
  className,
  isMenuCollapsed,
}) => {
  const { navigate, selectedCompanyId, selectCompany } = useArkaNavigation();

  const { myUser } = useMyUser();

  const myUserCompanyItems = myUser.companies.edges.map(
    ({ node: company }, index) => {
      const companyColor =
        AVATAR_COLOR_VARIANTS[index % AVATAR_COLOR_VARIANTS.length];
      return {
        company,
        key: company.id,
        content: (
          <div className="flex gap-12">
            <Avatar
              {...{
                name: company.name,
                color: companyColor,
              }}
            />
            <div>{company.name}</div>
          </div>
        ),

        isSelected: selectedCompanyId === company.id,
        color: companyColor,
      };
    }
  );

  const selectedCompanyItem = myUserCompanyItems.find(R.prop('isSelected'));

  return (
    <ContextMenu<(typeof myUserCompanyItems)[number]>
      {...{
        className,
        popoverProps: {
          placement: isMenuCollapsed ? 'right-end' : 'top-start',
        },
        menuProps: {
          onItemPress: item => {
            if (item.key) {
              selectCompany(item.key);

              // TODO remove after we completely switch to Apollo, now old http service can't refresh queries
              navigate(0);
            }
          },
          listActionButtonProps: {
            children: 'Управление компаниями',
            iconVariant: IconVariants.company,
            onPress: () => navigate(MASLOV_ROUTES_TREE.Auth.companySelector),
          },
        },
        items: myUserCompanyItems,
      }}
    >
      {({ isOpen, setIsOpen }) => {
        return (
          <NavigationMenuSelect
            {...{
              isOpen,
              onPress: () => setIsOpen(current => !current),
              iconElement: (
                <Avatar
                  {...{
                    name: selectedCompanyItem?.company.name,
                    color: selectedCompanyItem?.color,
                  }}
                />
              ),
              label: selectedCompanyItem?.company.name,
            }}
          />
        );
      }}
    </ContextMenu>
  );
};
