import React from 'react';

import clsx from 'clsx';

import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { ColorShades, getColorCssVarName } from '~/shared/helpers/color';

import { ColorVariants } from '~/styles/__generated__/token-variants';

import styles from './index.module.scss';

/**
 * Possible avatar colors to cycle through
 */
export const AVATAR_COLOR_VARIANTS = Object.values(ColorVariants).filter(
  variant => ![ColorVariants.neutral, ColorVariants.accent].includes(variant)
);

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Name of the entity, avatar is showing first letter as a default picture
   */
  name?: string;
  /**
   * Color of the avatar background
   */
  color?: ColorVariants;
}

export const Avatar: React.FC<Props> = ({
  className,
  name = '',
  color = ColorVariants.neutral,
}) => {
  return (
    <Typography
      {...{
        className: clsx(styles.root, className),
        variant: TypographyVariants.bodyMedium,
        style: {
          background: getColorCssVarName(color, ColorShades.containerDefault),
        },
      }}
    >
      {name[0] ?? '?'}
    </Typography>
  );
};
