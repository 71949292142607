import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router-dom';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';
import { MaslovNamespaces } from '~/~legacy/types/namespaces';

import { RequireAuthWrapper, useAuth } from '~/services/auth';
import {
  useGoogleAnalytics,
  useSentry,
  useYandexMetrika,
} from '~/services/vendorAnalytics';

import { BaseLayout } from '../BaseLayout';
import { RequireAuthRoutes } from '../RequireAuthRoutes';

const LoginPage = React.lazy(() =>
  import('~/pages/login').then(component => ({
    default: component.LoginPage,
  }))
);

export const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth();

  // Preload some translations to avoid glitch in modals
  useTranslation([MaslovNamespaces.enums, MaslovNamespaces.validation]);

  useYandexMetrika();
  useGoogleAnalytics();
  useSentry();

  return (
    <Routes>
      <Route
        key={0}
        path={MASLOV_ROUTES_TREE.Auth.login}
        element={
          <BaseLayout withMenu={false} withAuth={false}>
            <LoginPage />
          </BaseLayout>
        }
      />

      <Route
        path="*"
        element={
          isAuthenticated ? (
            <RequireAuthWrapper>
              <RequireAuthRoutes />
            </RequireAuthWrapper>
          ) : (
            <Navigate to={MASLOV_ROUTES_TREE.Auth.login} />
          )
        }
      />
    </Routes>
  );
};
