import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CowsCopyKeyFragmentDoc } from '../fragments/CowsCopyKey.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CowsCopyKeysQueryVariables = Types.Exact<{
  reason?: Types.InputMaybe<Types.CowsCopyReasonEnum>;
  isActivated?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type CowsCopyKeysQuery = { __typename?: 'Query', cowsCopyKeys: Array<{ __typename: 'CowsCopyKey', key: string, reason: Types.CowsCopyReasonEnum, departDate: string, arriveDate?: string | null, activatedAt?: string | null, toCompanyInn?: string | null, toCompanyName?: string | null, creator: { __typename: 'User', id: string, email: any, firstName: string, lastName: string, middleName: string, companies: { __typename?: 'UserCompanyConnection', edges: Array<{ __typename?: 'UserCompanyEdge', node: { __typename: 'Company', id: string }, blueprintRoles?: Array<{ __typename: 'BlueprintRole', id: string, name: string, color: Types.BlueprintRoleColor }> | null }> }, integrator?: { __typename: 'Integrator', id: string, name: string } | null }, company: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean }, toCompany?: { __typename: 'Company', id: string, name: string, isLockedForWrite: boolean } | null, cows: Array<{ __typename: 'Cow', id: string, identifier: number, name: string, state: Types.CowState, previousState?: Types.CowState | null, dateOfBirth: string }>, userEvents: Array<{ __typename: 'UserEvent', id: string, name: string, kind: Types.EventKindEnum }>, diseases: Array<{ __typename: 'Disease', id: string, name: string, kind: Types.EventKindEnum, protocols: Array<{ __typename: 'Protocol', id: string }> }>, inseminationBulls: Array<{ __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null }> }> };


export const CowsCopyKeysDocument = gql`
    query cowsCopyKeys($reason: CowsCopyReasonEnum, $isActivated: Boolean) {
  cowsCopyKeys(reason: $reason, isActivated: $isActivated) {
    ...CowsCopyKey
  }
}
    ${CowsCopyKeyFragmentDoc}`;

/**
 * __useCowsCopyKeysQuery__
 *
 * To run a query within a React component, call `useCowsCopyKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useCowsCopyKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCowsCopyKeysQuery({
 *   variables: {
 *      reason: // value for 'reason'
 *      isActivated: // value for 'isActivated'
 *   },
 * });
 */
export function useCowsCopyKeysQuery(baseOptions?: Apollo.QueryHookOptions<CowsCopyKeysQuery, CowsCopyKeysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CowsCopyKeysQuery, CowsCopyKeysQueryVariables>(CowsCopyKeysDocument, options);
      }
export function useCowsCopyKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CowsCopyKeysQuery, CowsCopyKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CowsCopyKeysQuery, CowsCopyKeysQueryVariables>(CowsCopyKeysDocument, options);
        }
export function useCowsCopyKeysSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CowsCopyKeysQuery, CowsCopyKeysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CowsCopyKeysQuery, CowsCopyKeysQueryVariables>(CowsCopyKeysDocument, options);
        }
export type CowsCopyKeysQueryHookResult = ReturnType<typeof useCowsCopyKeysQuery>;
export type CowsCopyKeysLazyQueryHookResult = ReturnType<typeof useCowsCopyKeysLazyQuery>;
export type CowsCopyKeysSuspenseQueryHookResult = ReturnType<typeof useCowsCopyKeysSuspenseQuery>;
export type CowsCopyKeysQueryResult = Apollo.QueryResult<CowsCopyKeysQuery, CowsCopyKeysQueryVariables>;