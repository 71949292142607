import React, { useMemo } from 'react';

import { VitalityFilter } from '@graphql-types';
import R from 'ramda';
import * as yup from 'yup';

import { ExpandableBlock } from '~/shared/components/ExpandableBlock';
import { Input, InputVariants } from '~/shared/components/Input';
import { Select, SelectVariants } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { InjectedModalProps, Modal } from '~/services/modals';

import { useCowSelect, useHandlePasteCowIdentifiers } from '~/entities/cows';

import formStyles from '~/styles/modules/form.module.scss';

import { MilkingParlorIntervalWithMilkingParlorFragment } from '../../gql/fragments/milkingParlorIntervalWithMilkingParlor.graphql';
import { useApplyCutCodesMutation } from '../../gql/mutations/applyCutCodes.graphql';
import styles from './index.module.scss';

export interface ApplyCutCodeModalProps
  extends InjectedModalProps<ApplyCutCodeModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Number of sorting gates in the milking parlor
   */
  sortingGatesCount: number;
  /**
   * Parlor interval to set code
   */
  milkingParlorInterval: MilkingParlorIntervalWithMilkingParlorFragment;
  /**
   * Date of the cut code tables
   */
  codesDate: string;
}

const FORM_ID = 'ApplyCutCodeForm';

const SCHEMA = yup.object({
  gateNumber: yup.number().default(null).required(),
  value: yup.number().default(null).required(),
  cowIDs: yup.array(yup.string().required()).default([]).min(1), // [ID!]
});

type ApplyCutCodeFormType = InferSchemaWithDefaults<typeof SCHEMA>;
type ApplyCutCodeFormTransformedType = InferValidatedSchema<typeof SCHEMA>;

export const ApplyCutCodeModal: React.FC<ApplyCutCodeModalProps> = ({
  className,
  sortingGatesCount,
  milkingParlorInterval,
  codesDate,

  close,
}) => {
  const formContext = useForm<
    ApplyCutCodeFormType,
    ApplyCutCodeFormTransformedType
  >({
    schema: SCHEMA,
    defaultValues: SCHEMA.getDefault(),
  });

  const sortingGatesSelectItems = useMemo(() => {
    return R.range(1, sortingGatesCount + 1).map(num => ({
      id: num,
      name: num,
    }));
  }, [sortingGatesCount]);

  const cowIDs = formContext.watch('cowIDs');
  const penGroupIDs = milkingParlorInterval.penGroups.edges.map(e => e.node.id);

  const {
    handlePasteCowIdentifiers,
    queryResult: { loading: isCowsLoading },
  } = useHandlePasteCowIdentifiers({
    onCowsLoaded: ({ cows }) => {
      const uniqueCows = R.uniq([
        ...cows.edges.map(cowEdge => cowEdge.node.id),
        ...cowIDs,
      ]);
      formContext.setValue('cowIDs', uniqueCows);
    },
    cowsQueryVariables: {
      penGroupIDs,
    },
  });

  const { renderSelectElement: renderCowSelectElement } = useCowSelect({
    queryOptions: {
      variables: {
        vitalityFilter: VitalityFilter.Alive,
        penGroupIDs,
      },
    },
    selectProps: {
      label: 'Номер коровы',
      labelProps: {
        tooltip:
          'Доступен массовый ввод — скопируйте список номеров коров и вставьте в поле для ввода',
        tooltipProps: {
          contentClassName: styles.tooltip,
        },
      },
      variant: SelectVariants.withItemsList,
      name: 'cowIDs',
      isMulti: true,
      loadingMessage: isCowsLoading
        ? 'Загружаем список добавленных коров...'
        : undefined,
      rawValue: cowIDs,
      onPaste: handlePasteCowIdentifiers,
    },
  });

  const [applyCutCodes, { loading: isLoading }] = useApplyCutCodesMutation();

  const handleSubmit = (form: ApplyCutCodeFormTransformedType) => {
    applyCutCodes({
      variables: {
        milkingParlorIntervalID: milkingParlorInterval.id,
        date: codesDate,
        input: form,
      },
      refetchQueries: ['cutCodes'],
    }).then(() => {
      close();
    });
  };

  return (
    <Modal
      {...{
        className,
        title: 'Присвоение кода сортировки',
        submitButtonProps: {
          form: FORM_ID,
          isLoading,
          children: 'Применить',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
        floatingFocusManagerProps: {
          // Skip expandable block to focus on first input
          initialFocus: 2,
        },
      }}
    >
      <ExpandableBlock
        {...{
          className: 'mb-16',
          openButtonText: 'Показать список кодов',
          closeButtonText: 'Скрыть список кодов',
          title: 'Список кодов сортировки',
        }}
      >
        <Typography
          {...{
            tag: 'ul',
            variant: TypographyVariants.bodySmall,
          }}
        >
          <li>0 — корова не должна быть отсортирована</li>
          <li>1 — корова должна быть отсортирована</li>
        </Typography>
      </ExpandableBlock>
      <Form
        {...{
          formContext,
          className: 'grid gap-16',
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <div className={formStyles.twoColumnForm}>
          <Select
            {...{
              name: 'gateNumber',
              placeholder: 'Выберите ворота',
              label: 'Сортировочные ворота',
              items: sortingGatesSelectItems,
            }}
          />
          <Input
            {...{
              name: 'value',
              label: 'Код сортировки',
              variant: InputVariants.int,
            }}
          />
        </div>
        {renderCowSelectElement()}
      </Form>
    </Modal>
  );
};
