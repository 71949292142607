import React, { useMemo } from 'react';

import { useApolloClient } from '@apollo/client';

import R from 'ramda';
import * as yup from 'yup';

import { Input } from '~/shared/components/Input';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';

import {
  Form,
  InferSchemaWithDefaults,
  InferValidatedSchema,
  useForm,
} from '~/services/forms';
import { makeDeleteQueriesByNameWithoutVariables } from '~/services/gql';
import { InjectedModalProps, Modal } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import { BlueprintRoleSelect } from '~/entities/blueprintRoles';
import { readBlueprintRoleFragment } from '~/entities/blueprintRoles/helpers';
import { useBlueprintSelect } from '~/entities/blueprints';
import { CustomReportsQueryVariables } from '~/entities/customReports/gql/queries/customReports.graphql';

import formStyles from '~/styles/modules/form.module.scss';

import { CustomReportFragment } from '../../gql/fragments/customReport.graphql';
import { useCreateCustomReportMutation } from '../../gql/mutations/createCustomReport.graphql';
import { useUpdateCustomReportMutation } from '../../gql/mutations/updateCustomReport.graphql';
import { updateCustomReportFragment } from '../../helpers';

export interface EditCustomReportModalProps
  extends InjectedModalProps<EditCustomReportModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Editing custom report, if not passed, a new one is created
   */
  customReport?: CustomReportFragment;
  /**
   * Called, when the report is saved
   */
  onSave?: (customReport?: CustomReportFragment) => void;
  /**
   * Gql custom reports query variables
   */
  queryVariables: CustomReportsQueryVariables;
}

const FORM_ID = 'EditCustomReportForm';

export const EditCustomReportModal: React.FC<EditCustomReportModalProps> = ({
  className,
  customReport,
  queryVariables,
  onSave,
  close,
}) => {
  const client = useApolloClient();

  const { sendSuccessToast } = useNotifications();

  const isEditing = !!customReport;

  const schema = useMemo(() => {
    return yup.object({
      name: yup.string().default('').required(),
      blueprintRoleIDs: yup.array(yup.string().default('')).default([]), // [ID]
      ...wrapConditionalObjectElement(
        !isEditing && {
          blueprintID: yup.string().required(), // ID!
        }
      ),
    });
  }, [isEditing]);

  type EditCustomReportFormType = InferSchemaWithDefaults<typeof schema>;
  type EditCustomReportFormTransformedType = InferValidatedSchema<
    typeof schema
  >;

  const formContext = useForm<
    EditCustomReportFormType,
    EditCustomReportFormTransformedType
  >({
    schema,
    defaultValues: {
      ...schema.getDefault(),
      ...R.pick(['name'], customReport ?? ({} as CustomReportFragment)),
      blueprintRoleIDs: customReport?.blueprintRoles.map(R.prop('id')),
    },
  });

  const [createCustomReport, { loading: isCreateCustomReportLoading }] =
    useCreateCustomReportMutation();

  const [updateCustomReport, { loading: isUpdateCustomReportLoading }] =
    useUpdateCustomReportMutation();

  const handleSubmit = async (form: EditCustomReportFormTransformedType) => {
    let customReportResult = customReport;
    if (isEditing) {
      await updateCustomReport({
        variables: {
          id: customReport.id,
          input: form,
        },
        optimisticResponse: { updateCustomReport: null },
        update: R.juxt([
          updateCustomReportFragment(customReport.id, draft => {
            draft.name = form.name;
            draft.blueprintRoles = form.blueprintRoleIDs
              .map(roleId => readBlueprintRoleFragment(client, roleId))
              .filter(Boolean);
          }),
          makeDeleteQueriesByNameWithoutVariables(
            'customReports',
            queryVariables
          ),
        ]),
      });
    } else {
      const createCustomReportData = await createCustomReport({
        variables: {
          input: form,
        },
        refetchQueries: ['customReports'],
      });
      customReportResult = createCustomReportData.data?.createCustomReport;
    }
    onSave?.(customReportResult);
    sendSuccessToast(isEditing ? 'Настройки сохранены' : 'Отчёт создан');

    close();
  };

  const { renderSelectElement: renderBlueprintSelectElement } =
    useBlueprintSelect({
      queryOptions: {
        variables: {
          canBeUsedInCustomReport: true,
        },
      },
      selectProps: {
        name: 'blueprintID',
        label: 'Список',
        placeholder: 'Выберите значение',
        rawValue: formContext.watch('blueprintID'),
      },
    });

  return (
    <Modal
      {...{
        className,
        title: isEditing ? 'Настройки отчёта' : 'Создание отчёта',
        submitButtonProps: {
          form: FORM_ID,
          isLoading: isCreateCustomReportLoading || isUpdateCustomReportLoading,
          children: isEditing ? 'Сохранить' : 'Создать',
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
      }}
    >
      <Form
        {...{
          formContext,
          className: formStyles.singleColumnForm,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Input
          {...{
            name: 'name',
            label: 'Название отчёта',
            placeholder: 'Введите название',
          }}
        />
        <BlueprintRoleSelect
          {...{
            name: 'blueprintRoleIDs',
            label: 'Пользователь отчёта',
            isMulti: true,
          }}
        />
        {!isEditing && renderBlueprintSelectElement()}
      </Form>
    </Modal>
  );
};
