import React, { useEffect } from 'react';

import R from 'ramda';

import { ColoredDot, ColoredDotSizes } from '~/shared/components/ColoredDot';
import {
  PartialSelectProps,
  PartialSelectPropsWithName,
} from '~/shared/components/Select';
import { ColorShades, getColorCssVarName } from '~/shared/helpers/color';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';

import { useMyUser } from '~/services/auth';
import { makeAsyncSelectFormComponent } from '~/services/forms';
import { makeUseAsyncSelect, UseAsyncSelectProps } from '~/services/gql';
import { useArkaNavigation } from '~/services/navigation';

import { BLUEPRINT_ROLE_COLORS_CONFIGS } from '../../constants';
import {
  BlueprintRoleFragment,
  BlueprintRoleFragmentDoc,
} from '../../gql/fragments/blueprintRole.graphql';
import {
  BlueprintRolesQuery,
  BlueprintRolesQueryVariables,
  useBlueprintRolesQuery,
} from '../../gql/queries/blueprintRoles.graphql';

/**
 * Select for a blueprintRole
 */
const useBlueprintRoleSelectInner = makeUseAsyncSelect({
  typeName: 'BlueprintRole',
  fragment: BlueprintRoleFragmentDoc,
  useQuery: useBlueprintRolesQuery,
  getItemsFromQueryData: data => data.blueprintRoles.nodes,
  getPageInfoFromQueryData: R.always(undefined),

  selectProps: {
    label: 'Должность',
    placeholder: 'Выберите должность',
    getItemValue: item => item.id,
    getItemText: item => item?.name ?? '',
    renderItemText: item => {
      if (!item) return null;

      const { colorVariant } = BLUEPRINT_ROLE_COLORS_CONFIGS[item.color];

      return (
        <div className="flex items-center">
          <ColoredDot
            {...{
              className: 'mr-8',
              color: getColorCssVarName(colorVariant, ColorShades.default),
              size: ColoredDotSizes.medium12,
            }}
          />
          {item.name}
        </div>
      );
    },
  },
});

type useBlueprintRoleSelectProps = {
  selectProps: PartialSelectPropsWithName<BlueprintRoleFragment> & {
    isUsedAsFilter?: boolean;
  };
} & Pick<
  UseAsyncSelectProps<
    BlueprintRoleFragment,
    BlueprintRolesQuery,
    BlueprintRolesQueryVariables
  >,
  'onFirstLoad' | 'queryOptions'
>;

export const useBlueprintRoleSelect = ({
  selectProps: { isUsedAsFilter = false, ...selectProps },
  ...props
}: useBlueprintRoleSelectProps) => {
  const { getRolesByCompanyId } = useMyUser();
  const { selectedCompanyId } = useArkaNavigation();

  const myBlueprintRoles = getRolesByCompanyId(selectedCompanyId);

  const {
    renderSelectElement: renderRoleSelectElement,
    items: blueprintRoleItems,
    itemsPromise: blueprintRoleItemsPromise,
    ...blueprintRoleSelectInnerResult
  } = useBlueprintRoleSelectInner({
    selectProps: {
      isClearable: true,
      ...wrapConditionalObjectElement(
        !!myBlueprintRoles.length && {
          items: myBlueprintRoles,
        }
      ),
      ...selectProps,
    },
    ...props,
  });

  // Setting default blueprint role value after fetching all the roles
  useEffect(() => {
    if (!isUsedAsFilter) return;

    const setBlueprintRoles = (roles: BlueprintRoleFragment[]) => {
      if (selectProps.isMulti) {
        selectProps.onValueChange?.(roles.slice(0, 1));
      } else {
        selectProps.onValueChange?.(roles[0]);
      }
    };

    blueprintRoleItemsPromise.then(roleItems => {
      if (myBlueprintRoles.length === 1) {
        setBlueprintRoles(myBlueprintRoles);
      }
      if (!myBlueprintRoles.length && roleItems.length === 1) {
        setBlueprintRoles(roleItems);
      }
    });
  }, [isUsedAsFilter]);

  return {
    renderSelectElement: (
      selectElementProps?: PartialSelectProps<BlueprintRoleFragment>
    ) =>
      renderRoleSelectElement({
        isDisabled:
          isUsedAsFilter &&
          (myBlueprintRoles.length === 1 ||
            (!myBlueprintRoles.length && blueprintRoleItems.length === 1)),

        ...selectElementProps,
      }),
    items: blueprintRoleItems,
    itemsPromise: blueprintRoleItemsPromise,
    ...blueprintRoleSelectInnerResult,
  };
};

export const BlueprintRoleSelect = makeAsyncSelectFormComponent(
  useBlueprintRoleSelect
);
