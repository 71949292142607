import React, { useEffect, useState } from 'react';

import R from 'ramda';
import * as yup from 'yup';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { Input, InputProps, InputVariants } from '~/shared/components/Input';
import { Select } from '~/shared/components/Select';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatWithPercent } from '~/shared/helpers/number';
import { ValueOf } from '~/shared/types/utility';

import { Form, InferValidatedSchema, useForm } from '~/services/forms';
import { makeDeleteQuery } from '~/services/gql';
import { InjectedModalProps, Modal } from '~/services/modals';

import { FarmFragment } from '~/entities/farms/gql/fragments/farm.graphql';

import formStyles from '~/styles/modules/form.module.scss';

import { LivestockForecastSettingFragment } from '../../gql/fragments/livestockForecastSetting.graphql';
import { useUpdateLivestockForecastSettingMutation } from '../../gql/mutations/updateLivestockForecastSetting.graphql';
import { useLivestockForecastSettingsQuery } from '../../gql/queries/livestockForecastSettings.graphql';

export interface EditLivestockForecastSettingModalProps
  extends InjectedModalProps<EditLivestockForecastSettingModalProps> {
  /**
   * className applied to the root element
   */
  className?: string;
}

const FORM_ID = 'EditLivestockForecastSettingForm';

const SETTINGS_FIELD_NAMES = [
  'dryPeriod',
  'freshPeriod',
  'cowsVoluntaryWaitingPeriod',
  'heifersVoluntaryWaitingPeriod',
  'cowsPregnancyRate',
  'heifersPregnancyRate',
  'cowsLeftInFirstLactRate',
  'cowsLeftInOtherLactRate',
  'bullsLeftZeroToTwoMonthRate',
  'bullsLeftThreeToSixMonthRate',
  'bullsLeftSevenToTwelveMonthRate',
  'heifersLeftZeroToTwoMonthRate',
  'heifersLeftThreeToSixMonthRate',
  'heifersLeftSevenToTwelveMonthRate',
  'heifersInFirstLactRate',
  'heifersInOtherLactRate',
  'stillbirthsInFirstLactRate',
  'stillbirthsInOtherLactRate',
] as const;

type SettingsFieldNames = ValueOf<typeof SETTINGS_FIELD_NAMES>;

const formatWithDay = (val: string | number) => `${val} дней`;

const SETTING_FIELDS_FORMATTERS: Record<
  SettingsFieldNames,
  (val: string | number) => string | number
> = {
  dryPeriod: formatWithDay,
  freshPeriod: formatWithDay,
  cowsVoluntaryWaitingPeriod: formatWithDay,
  heifersVoluntaryWaitingPeriod: formatWithDay,

  cowsPregnancyRate: formatWithPercent,
  heifersPregnancyRate: formatWithPercent,
  cowsLeftInFirstLactRate: formatWithPercent,
  cowsLeftInOtherLactRate: formatWithPercent,
  bullsLeftZeroToTwoMonthRate: formatWithPercent,
  bullsLeftThreeToSixMonthRate: formatWithPercent,
  bullsLeftSevenToTwelveMonthRate: formatWithPercent,
  heifersLeftZeroToTwoMonthRate: formatWithPercent,
  heifersLeftThreeToSixMonthRate: formatWithPercent,
  heifersLeftSevenToTwelveMonthRate: formatWithPercent,
  heifersInFirstLactRate: formatWithPercent,
  heifersInOtherLactRate: formatWithPercent,
  stillbirthsInFirstLactRate: formatWithPercent,
  stillbirthsInOtherLactRate: formatWithPercent,
};

const SCHEMA = yup.object({
  dryPeriod: yup.number().default(null).nullable().positive(),
  freshPeriod: yup.number().default(null).nullable().positive(),
  cowsVoluntaryWaitingPeriod: yup.number().default(null).nullable().positive(),
  heifersVoluntaryWaitingPeriod: yup
    .number()
    .default(null)
    .nullable()
    .positive(),

  cowsPregnancyRate: yup.number().default(null).nullable().max(100),
  heifersPregnancyRate: yup.number().default(null).nullable().max(100),
  cowsLeftInFirstLactRate: yup.number().default(null).nullable().max(100),
  cowsLeftInOtherLactRate: yup.number().default(null).nullable().max(100),
  bullsLeftZeroToTwoMonthRate: yup.number().default(null).nullable().max(100),
  bullsLeftThreeToSixMonthRate: yup.number().default(null).nullable().max(100),
  bullsLeftSevenToTwelveMonthRate: yup
    .number()
    .default(null)
    .nullable()
    .max(100),
  heifersLeftZeroToTwoMonthRate: yup.number().default(null).nullable().max(100),
  heifersLeftThreeToSixMonthRate: yup
    .number()
    .default(null)
    .nullable()
    .max(100),
  heifersLeftSevenToTwelveMonthRate: yup
    .number()
    .default(null)
    .nullable()
    .max(100),
  heifersInFirstLactRate: yup.number().default(null).nullable().max(100),
  heifersInOtherLactRate: yup.number().default(null).nullable().max(100),
  stillbirthsInFirstLactRate: yup.number().default(null).nullable().max(100),
  stillbirthsInOtherLactRate: yup.number().default(null).nullable().max(100),
});

type EditLivestockForecastSettingFormType = InferValidatedSchema<typeof SCHEMA>;

const getCalcFieldName = (fieldName: SettingsFieldNames) =>
  `${fieldName}Calc` as const;

const mapForecastSettingToForm = (
  forecastSetting: LivestockForecastSettingFragment,
  isOnlyCalcValues = false
) => {
  return Object.fromEntries(
    SETTINGS_FIELD_NAMES.map(fieldName => {
      const calcFieldValue = forecastSetting[getCalcFieldName(fieldName)];
      let fieldValue = forecastSetting[fieldName] ?? calcFieldValue;
      if (isOnlyCalcValues) {
        fieldValue = calcFieldValue;
      }
      return [fieldName, fieldValue];
    })
  );
};

export const EditLivestockForecastSettingModal: React.FC<
  EditLivestockForecastSettingModalProps
> = ({ className, close }) => {
  const [farmId, setFarmId] = useState<string>();

  const [updateLivestockForecastSetting] =
    useUpdateLivestockForecastSettingMutation();

  const { data, loading: isLoading } = useLivestockForecastSettingsQuery({
    onCompleted: ({ livestockForecastSettings }) => {
      setFarmId(livestockForecastSettings[0].farm.id);
    },
  });
  const forecastSettingsArray = data?.livestockForecastSettings ?? [];

  const currentSettings = forecastSettingsArray.find(
    ({ farm }) => farm.id === farmId
  );

  const formContext = useForm<EditLivestockForecastSettingFormType>({
    schema: SCHEMA,
    defaultValues: SCHEMA.getDefault(),
  });

  const handleSubmit = (form: EditLivestockForecastSettingFormType) => {
    if (!currentSettings) return;

    const formToSend = Object.fromEntries(
      SETTINGS_FIELD_NAMES.map(fieldName => [
        fieldName,
        form[fieldName] === currentSettings[getCalcFieldName(fieldName)]
          ? null
          : form[fieldName],
      ])
    );

    updateLivestockForecastSetting({
      variables: {
        id: currentSettings.id,
        input: formToSend,
      },
      update: makeDeleteQuery('livestockForecastSettings'),
    });

    close();
  };

  // Reset form with new forecast settings, when we select different farm
  useEffect(() => {
    if (!farmId || !forecastSettingsArray.length || !currentSettings) return;

    formContext.reset(mapForecastSettingToForm(currentSettings));
  }, [farmId, forecastSettingsArray]);

  const formValues = formContext.watch();

  const hasModifiedFields = SETTINGS_FIELD_NAMES.some(
    fieldName =>
      formValues[fieldName] !== currentSettings?.[getCalcFieldName(fieldName)]
  );

  // Render helpers
  const sectionHeaderProps = {
    className: formStyles.sectionHeader,
    tag: 'h2',
    variant: TypographyVariants.bodyMediumStrong,
  } as const;

  const renderInput = (
    inputProps: InputProps & { name: SettingsFieldNames }
  ) => {
    if (!currentSettings) return null;

    const inputValue = formValues[inputProps.name];
    const calcValue = currentSettings[getCalcFieldName(inputProps.name)];

    const formatCalValue = SETTING_FIELDS_FORMATTERS[inputProps.name];
    const feedback =
      inputValue === calcValue
        ? undefined
        : `Расчёт. значение: ${formatCalValue(calcValue)}`;

    return (
      <Input
        {...{
          ...inputProps,
          feedback,
        }}
      />
    );
  };

  return (
    <Modal
      {...{
        className,
        title: 'Настройки отчёта',
        isLoading,
        submitButtonProps: {
          form: FORM_ID,
        },
        isRequireExplicitClosing: formContext.formState.isDirty,
        additionalButtons: [
          <Button
            {...{
              key: 'reset',
              className: 'mr-a',
              variant: ButtonVariants.ghost,
              isDisabled: !currentSettings || !hasModifiedFields,
              onPress: () => {
                if (!currentSettings) return;
                formContext.reset(
                  mapForecastSettingToForm(currentSettings, true)
                );
              },
              children: 'Сбросить настройки',
            }}
          />,
        ],
      }}
    >
      {forecastSettingsArray.length > 1 && (
        <Select<FarmFragment>
          {...{
            name: 'farmId',
            className: 'mb-32',
            withFormContext: false,
            label: 'Ферма',
            items: forecastSettingsArray.map(R.prop('farm')),
            rawValue: farmId,
            onValueChange: newValue => setFarmId(newValue?.id),
          }}
        />
      )}
      <Form
        {...{
          formContext,
          id: FORM_ID,
          onSubmit: formContext.handleSubmit(handleSubmit),
        }}
      >
        <Typography {...sectionHeaderProps}>Воспроизводство</Typography>
        <div className={formStyles.twoColumnForm}>
          {renderInput({
            name: 'dryPeriod',
            label: 'Срок перевода в сухостой, дней',
            variant: InputVariants.int,
          })}
          {renderInput({
            name: 'freshPeriod',
            label: 'Срок отёла, дней',
            variant: InputVariants.int,
          })}
        </div>

        <Typography {...sectionHeaderProps}>
          Период добровольного ожидания
        </Typography>
        <div className={formStyles.twoColumnForm}>
          {renderInput({
            name: 'cowsVoluntaryWaitingPeriod',
            label: 'ПДО у коров, дней',
            variant: InputVariants.int,
          })}
          {renderInput({
            name: 'heifersVoluntaryWaitingPeriod',
            label: 'ПДО у тёлок, дней',
            variant: InputVariants.int,
          })}
        </div>

        <Typography {...sectionHeaderProps}>
          Процент мертворождённых телят
        </Typography>
        <div className={formStyles.twoColumnForm}>
          {renderInput({
            name: 'stillbirthsInFirstLactRate',
            label: 'Первотёлки',
            variant: InputVariants.percent,
          })}
          {renderInput({
            name: 'stillbirthsInOtherLactRate',
            label: 'Лактация ≥ 2',
            variant: InputVariants.percent,
          })}
        </div>

        <Typography {...sectionHeaderProps}>Показатели</Typography>
        <div className={formStyles.twoColumnForm}>
          {renderInput({
            name: 'cowsPregnancyRate',
            label: 'Стельность (PR) у коров',
            variant: InputVariants.percent,
          })}
          {renderInput({
            name: 'heifersPregnancyRate',
            label: 'Стельность (PR) у тёлок',
            variant: InputVariants.percent,
          })}
        </div>

        <Typography {...sectionHeaderProps}>Процент рождения тёлок</Typography>
        <div className={formStyles.twoColumnForm}>
          {renderInput({
            name: 'heifersInFirstLactRate',
            label: 'Первотёлки',
            variant: InputVariants.percent,
          })}
          {renderInput({
            name: 'heifersInOtherLactRate',
            label: 'Лактация ≥ 2',
            variant: InputVariants.percent,
          })}
        </div>

        <Typography {...sectionHeaderProps}>Выбытие коров</Typography>
        <div className={formStyles.twoColumnForm}>
          {renderInput({
            name: 'cowsLeftInFirstLactRate',
            label: 'Первотёлки',
            variant: InputVariants.percent,
          })}
          {renderInput({
            name: 'cowsLeftInOtherLactRate',
            label: 'Лактация ≥ 2',
            variant: InputVariants.percent,
          })}
        </div>

        <Typography {...sectionHeaderProps}>Выбытие тёлок</Typography>
        <div className={formStyles.threeColumnForm}>
          {renderInput({
            name: 'heifersLeftZeroToTwoMonthRate',
            label: 'От 0 до 2 месяцев',
            variant: InputVariants.percent,
          })}
          {renderInput({
            name: 'heifersLeftThreeToSixMonthRate',
            label: 'От 3 до 6 месяцев',
            variant: InputVariants.percent,
          })}
          {renderInput({
            name: 'heifersLeftSevenToTwelveMonthRate',
            label: 'От 6 до 12 месяцев',
            variant: InputVariants.percent,
          })}
        </div>

        <Typography {...sectionHeaderProps}>Выбытие быков</Typography>
        <div className={formStyles.threeColumnForm}>
          {renderInput({
            name: 'bullsLeftZeroToTwoMonthRate',
            label: 'От 0 до 2 месяцев',
            variant: InputVariants.percent,
          })}
          {renderInput({
            name: 'bullsLeftThreeToSixMonthRate',
            label: 'От 3 до 6 месяцев',
            variant: InputVariants.percent,
          })}
          {renderInput({
            name: 'bullsLeftSevenToTwelveMonthRate',
            label: 'От 6 до 12 месяцев',
            variant: InputVariants.percent,
          })}
        </div>
      </Form>
    </Modal>
  );
};
