import React, { DependencyList, useCallback, useEffect, useRef } from 'react';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import { useResizeObserver } from '@react-aria/utils';
import clsx from 'clsx';

import { LAYOUT_ROOT_ID } from '~/shared/constants';
import { useElementSize } from '~/shared/hooks/useElementSize';
import { useEventListener } from '~/shared/hooks/useEventListener';
import { useIsOverflow } from '~/shared/hooks/useIsOverflow';

import TOKENS from '~/styles/__generated__/tokens.json';
import customScrollStyles from '~/styles/modules/customScroll.module.scss';
import panelStyles from '~/styles/modules/panel.module.scss';

interface UseCustomScrollWrapperProps {
  /**
   * If true, scroll container is automatically scrolled to its scrollWidth, when autoScrollDeps changes
   */
  withAutoScrollToEnd?: boolean;
  /**
   * If passed, used to trigger auto scroll, by default triggers only on mount
   */
  autoScrollDeps?: DependencyList;
  /**
   * If true, doesn't render ScrollSyncPane if no overflow occurs
   * Layout shift may occur, if this is set to true (default - true)
   */
  shouldHideScrollBarsForNoOverflow?: boolean;
  /**
   * If true, it means, we're using some custom border styles,
   * so we should apply them to fixed sticky element
   */
  withCustomBorder?: boolean;
  /**
   * If true, renders a .panel for the main scroll wrapper (default - true)
   * TODO remove when we remove tertiary table
   */
  withPanel?: boolean;
}

interface RenderCustomScrollWrapperProps extends React.PropsWithChildren {
  /**
   * className applied to the scroll container element
   */
  className?: string;
  /**
   * className applied to the content scroll wrapper
   */
  contentClassName?: string;
  /**
   * Key prefix for rendering scrollbars, should be different for different contentWidth values
   */
  scrollBarKey: React.Key;
}

const STICKY_ELEMENT_SELECTOR = '[data-sticky-element="true"]';

/**
 * Hook for rendering custom scroll bar at the bottom or at the top of the table
 */
export const useCustomScrollWrapper = ({
  autoScrollDeps = [],
  withAutoScrollToEnd = !!autoScrollDeps.length,
  shouldHideScrollBarsForNoOverflow = true,
  withCustomBorder = false,
  withPanel = true,
}: UseCustomScrollWrapperProps = {}) => {
  const { ref: useIsOverflowRef, isOverflow } = useIsOverflow();

  const [useScrollMeasureRef, { width: contentWidth }] = useElementSize();

  // Scroll to end of container, based on passed deps
  useEffect(() => {
    if (!withAutoScrollToEnd) return;

    // Timeout for ScrollSync to update first to avoid glitch with not synced scrollbar
    setTimeout(() => {
      if (!useIsOverflowRef.current) {
        return;
      }
      useIsOverflowRef.current.scrollTo(
        useIsOverflowRef.current.scrollWidth,
        0
      );
    }, 0);
  }, [withAutoScrollToEnd, ...autoScrollDeps]);

  const stickyElementWrapperContainerRef =
    useRef<React.ElementRef<'div'>>(null);
  const scrollHoverContainerRef = useRef<React.ElementRef<'div'>>(null);

  // This scroll handler adds ability
  // to have nested sticky elements in the scroll container,
  // which we can't have with css sticky position,
  // by cloning an element with data-sticky-element="true" and applying fixed position
  const handleStickyContentUpdate = useCallback(
    (shouldSkipIfHasChildren: boolean = true) => {
      if (
        !scrollHoverContainerRef.current ||
        !stickyElementWrapperContainerRef.current ||
        !useIsOverflowRef.current
      ) {
        return;
      }

      const stickyElement = scrollHoverContainerRef.current.querySelector(
        STICKY_ELEMENT_SELECTOR
      );
      const stickyElementRoot = stickyElement?.parentElement;
      const layoutRoot = document.getElementById(LAYOUT_ROOT_ID);

      if (!stickyElement || !layoutRoot || !stickyElementRoot) return;

      // We always expect a px value for --sticky-header-top-current, so we convert it to number
      const stickyHeaderTopCurrentPx = parseInt(
        getComputedStyle(layoutRoot).getPropertyValue(
          '--sticky-header-top-current'
        ),
        10
      );

      const stickyElementRect = stickyElement.getBoundingClientRect();

      if (
        // If we've not yet scrolled to the sticky element, remove fixed sticky element
        stickyElementRect.y > stickyHeaderTopCurrentPx ||
        // If we've scrolled through all the root element, remove fixed sticky element
        stickyElementRoot.getBoundingClientRect().y -
          stickyHeaderTopCurrentPx -
          stickyElementRect.height +
          stickyElementRoot.scrollHeight <
          0
      ) {
        stickyElementWrapperContainerRef.current.replaceChildren();
        return;
      }

      // Return if we've already added sticky element
      const hasChildren =
        stickyElementWrapperContainerRef.current.hasChildNodes();

      if (shouldSkipIfHasChildren && hasChildren) return;

      // Create a clone of the sticky element and fix it on the top with a scrolling wrapper
      // If we already have the clone, just get it
      const stickyElementRootClone = hasChildren
        ? stickyElementWrapperContainerRef.current.childNodes[0]
        : stickyElementRoot.cloneNode();

      if (!(stickyElementRootClone instanceof HTMLElement)) {
        return;
      }

      // If we didn't have the root clone, append sticky element to it
      if (!hasChildren) {
        const stickyElementClone = stickyElement.cloneNode(true);
        stickyElementRootClone.appendChild(stickyElementClone);
      }

      if (stickyElementRoot.style.gridTemplateColumns) {
        // Apply computed values for template columns,
        // cause we may have some non-sticky content, which is not cloned here,
        // defining the grid by min/max-content or auto columns
        stickyElementRootClone.style.gridTemplateColumns =
          getComputedStyle(stickyElementRoot).gridTemplateColumns;
      }

      // Append cloned content to the fixed sticky element
      if (!hasChildren) {
        stickyElementWrapperContainerRef.current.appendChild(
          stickyElementRootClone
        );
      }

      stickyElementWrapperContainerRef.current.style.width = `${useIsOverflowRef.current.clientWidth}px`;

      if (withCustomBorder) {
        // If we have a custom border style on the main scrolled panel,
        // we should apply it as a translate to fixed element

        const mainScrollPanelLeftBorderWidth = getComputedStyle(
          useIsOverflowRef.current
        ).borderLeftWidth;
        stickyElementWrapperContainerRef.current.style.translate =
          mainScrollPanelLeftBorderWidth;
      }

      // Sync scroll
      stickyElementWrapperContainerRef.current.scrollLeft =
        useIsOverflowRef.current.scrollLeft;
    },
    [withCustomBorder]
  );

  useEventListener('scroll', () => handleStickyContentUpdate(), undefined, {
    passive: true,
  });

  useResizeObserver({
    ref: scrollHoverContainerRef,
    onResize: () => {
      handleStickyContentUpdate(false);
    },
  });

  const shouldRenderScrollbars =
    !shouldHideScrollBarsForNoOverflow || isOverflow;

  const renderCustomScrollWrapper = ({
    className,
    contentClassName,
    scrollBarKey,
    children,
  }: RenderCustomScrollWrapperProps) => {
    const renderScrollSyncPane = (isTop: boolean) => (
      <ScrollSyncPane>
        <div
          key={`${scrollBarKey}__${isTop ? 'top' : 'bottom'}`}
          className={clsx(
            'full-width',
            customScrollStyles.customScrollBaseContainer,
            isTop ? 'pb-8 mb-4' : 'pb-4',
            isOverflow ? 'overflow-auto' : 'overflow-hidden'
          )}
        >
          <div style={{ width: contentWidth }} />
        </div>
      </ScrollSyncPane>
    );

    return (
      <ScrollSync key={scrollBarKey}>
        <>
          <ScrollSyncPane innerRef={stickyElementWrapperContainerRef}>
            <div
              {...{
                className: 'position-fixed overflow-auto hidden-scrollbar',
                style: {
                  zIndex: TOKENS.zIndexMenu - 10,
                  top: 'var(--sticky-header-top-current)',
                },
                'data-fixed-sticky-element-container': true,
              }}
            />
          </ScrollSyncPane>
          <div
            ref={scrollHoverContainerRef}
            className={clsx(
              className,
              customScrollStyles.customScrollHoverContainer
            )}
          >
            {shouldRenderScrollbars && renderScrollSyncPane(true)}
            <ScrollSyncPane innerRef={useIsOverflowRef}>
              <div
                className={clsx(
                  'overflow-auto hidden-scrollbar',
                  customScrollStyles.customScrollBaseContainer,
                  contentClassName,
                  withPanel && panelStyles.panel
                )}
              >
                {children}
              </div>
            </ScrollSyncPane>
            {shouldRenderScrollbars && renderScrollSyncPane(false)}
          </div>
        </>
      </ScrollSync>
    );
  };

  return {
    useScrollMeasureRef,
    renderCustomScrollWrapper,
  };
};
