import React, { CSSProperties, useCallback, useRef, useState } from 'react';

import {
  FloatingFocusManager,
  FloatingOverlay,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { animated } from '@react-spring/web';
import clsx from 'clsx';

import { Button, ButtonVariants } from '~/shared/components/Button';
import { CloseButton } from '~/shared/components/CloseButton';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { wrapConditionalArrayElement } from '~/shared/helpers/array';
import { wrapConditionalObjectElement } from '~/shared/helpers/object';
import { useEventListener } from '~/shared/hooks/useEventListener';

import { useModal } from '~/services/modals';

import panelStyles from '~/styles/modules/panel.module.scss';

import { useSingleModalContext } from '../../context';
import { ModalComponentProps, ModalSizes } from '../../types';
import styles from './index.module.scss';

const SHAKE_MODAL_TIMEOUT_MS = 300;

export const Modal: React.FC<ModalComponentProps> = ({
  className,
  contentClassName,

  title,
  subtitle,

  size = ModalSizes.small516,

  stepsCount = 1,
  stepNumber = 1,

  isRequireExplicitClosing = false,
  isLoading = false,

  onSubmit,
  shouldShowSubmitButton = true,
  submitButtonProps,

  onCancel,
  cancelButtonProps,

  additionalButtons = [],
  renderButtons,

  withOpenAnimation = true,

  floatingFocusManagerProps,

  onClose,

  children,
}) => {
  const { modalName, transitionStyles } = useSingleModalContext();

  const { isOpen, close, stackIndex } = useModal(modalName);

  const handleCancel = useCallback(() => {
    onCancel?.();
    onClose?.();
    close();
  }, [onCancel]);

  const { context: floatingContext, floatingStyles } = useFloating({
    open: isOpen,
    strategy: 'fixed',
    onOpenChange: newIsOpen => {
      if (!newIsOpen) {
        handleCancel();
      }
    },
  });

  const shakeModalIntervalRef = useRef<NodeJS.Timeout>();
  const [shouldShakeModal, setShouldShakeModal] = useState(false);
  const dismiss = useDismiss(floatingContext, {
    outsidePressEvent: 'mousedown',
    outsidePress: event => {
      const target = event.target as HTMLElement;

      const isClickOnLegacyModal = target.closest('.m-modal');
      const isClickOnSelect = target.closest(`[data-floating-select=true]`);
      const isClickOnModal = target.closest(
        `[data-floating-modal-stack-index]:not([data-floating-modal-stack-index="${stackIndex}"])`
      );

      const isClickOutside =
        !isClickOnModal && !isClickOnLegacyModal && !isClickOnSelect;

      if (!isRequireExplicitClosing) {
        return isClickOutside;
      }

      if (shakeModalIntervalRef.current) {
        setShouldShakeModal(false);
        clearTimeout(shakeModalIntervalRef.current);
        shakeModalIntervalRef.current = undefined;
      }
      setShouldShakeModal(isClickOutside);

      shakeModalIntervalRef.current = setTimeout(() => {
        setShouldShakeModal(false);

        shakeModalIntervalRef.current = undefined;
      }, SHAKE_MODAL_TIMEOUT_MS);

      return false;
    },
  });
  const role = useRole(floatingContext);

  const interactions = useInteractions([dismiss, role]);

  useEventListener('beforeunload', e => {
    if (isRequireExplicitClosing) {
      e.preventDefault();
    }
  });

  const isStepsEnabled = stepsCount > 1;
  const shouldShowCancelButton =
    (isStepsEnabled && stepNumber > 1) || !isStepsEnabled;

  const buttons = [
    ...additionalButtons,
    ...wrapConditionalArrayElement(
      shouldShowCancelButton && (
        <Button
          {...{
            key: 'cancel',
            variant: ButtonVariants.secondary,
            onPress: handleCancel,
            ...cancelButtonProps,
            ...wrapConditionalObjectElement(
              isStepsEnabled && stepNumber > 1
                ? {
                    children: 'Назад',
                    iconVariant: IconVariants.arrowUp,
                    iconProps: {
                      rotate: RotateVariants.left,
                    },
                  }
                : {
                    children: cancelButtonProps?.children ?? 'Отмена',
                  }
            ),
          }}
        />
      )
    ),
    ...wrapConditionalArrayElement(
      shouldShowSubmitButton && (
        <Button
          {...{
            key: 'submit',
            type: 'submit',
            onPress: onSubmit,
            ...submitButtonProps,
            ...wrapConditionalObjectElement(
              isStepsEnabled && stepNumber < stepsCount
                ? {
                    children: 'Продолжить',
                    rightIconVariant: IconVariants.arrowUp,
                    iconProps: {
                      rotate: RotateVariants.right,
                    },
                  }
                : {
                    children: submitButtonProps?.children ?? 'Сохранить',
                  }
            ),
          }}
        />
      )
    ),
  ];

  return (
    <FloatingFocusManager
      {...{
        // Skip first element that should be the close button
        initialFocus: 1,
        disabled: isLoading,
        context: floatingContext,
        returnFocus: false,
        ...floatingFocusManagerProps,
      }}
    >
      <FloatingOverlay
        {...{
          lockScroll: true,
          className: clsx(styles.overlay, styles[size], {
            [styles.fadeIn]: withOpenAnimation && isOpen,
            [styles.fadeOut]: !isOpen,
          }),
          style: {
            '--modal-stack-index': stackIndex,
            ...floatingStyles,
          } as CSSProperties,
          'data-floating-modal-stack-index': stackIndex,
        }}
      >
        <animated.div
          ref={floatingContext.refs.setFloating}
          {...interactions.getFloatingProps({
            className: clsx(
              'light-panel',
              className,
              styles.modal,
              shouldShakeModal && styles.shake
            ),
            style: transitionStyles,
          })}
        >
          <div className={styles.header}>
            <div className=" flex flex-col gap-8">
              <Typography variant={TypographyVariants.heading2}>
                {title}
              </Typography>
              {!!subtitle && (
                <Typography
                  tag="h3"
                  variant={TypographyVariants.descriptionLarge}
                  className="text-soft"
                >
                  {subtitle}
                </Typography>
              )}
            </div>

            <CloseButton
              {...{
                onPress: handleCancel,
                iconProps: {
                  shouldContinuePropagation: true,
                },
              }}
            />
          </div>
          {(!!children || isLoading) && (
            <div
              className={clsx(
                styles.content,
                contentClassName,
                isLoading && 'grid items-center'
              )}
            >
              {isLoading && (
                <div className={clsx('p-24', panelStyles.borderedPanel)}>
                  <DataBlockedMessage
                    {...{
                      className: 'p-24',
                      isLoading,
                      message: 'Пожалуйста, подождите',
                    }}
                  />
                </div>
              )}
              {!isLoading && children}
            </div>
          )}
          {!isLoading && renderButtons?.()}
          {!isLoading && !renderButtons && (
            <div className={styles.buttons}>{buttons}</div>
          )}
        </animated.div>
      </FloatingOverlay>
    </FloatingFocusManager>
  );
};
