import React from 'react';

import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { ColorShades, getColorCssVarName } from '~/shared/helpers/color';

import {
  ColorVariants,
  SizeVariants,
} from '~/styles/__generated__/token-variants';

import styles from './index.module.scss';

/**
 * Possible badge size variants
 */
export enum BadgeSizes {
  medium24 = 'medium24',
  small16 = 'small16',
}

interface Props extends React.PropsWithChildren {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * Size variant of the badge
   */
  size?: BadgeSizes;
  /**
   * If true, uses full rounded corners, design system uses this variant for counters
   */
  isPill?: boolean;
  /**
   * Icon variant to display with text
   */
  iconVariant?: IconVariants;

  /**
   * Badge color theme
   */
  color?: ColorVariants;
}

export const Badge = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      size = BadgeSizes.medium24,
      isPill,
      iconVariant,
      color = ColorVariants.neutral,
      children,
    },
    ref
  ) => {
    return (
      <span
        {...{
          className: clsx(
            styles.root,
            isPill && styles.pill,
            styles[size],
            className
          ),
          style: {
            color: getColorCssVarName(color, ColorShades.hover),
            backgroundColor: getColorCssVarName(
              color,
              ColorShades.containerDefault
            ),
          },
          ref,
        }}
      >
        {iconVariant && (
          <Icon
            {...{
              variant: iconVariant,
              size: SizeVariants.size16,
            }}
          />
        )}
        <Typography
          {...{
            className: styles.text,
            variant:
              size === BadgeSizes.medium24
                ? TypographyVariants.bodySmallStrong
                : TypographyVariants.descriptionLargeStrong,
          }}
        >
          {children}
        </Typography>
      </span>
    );
  }
);
