import React from 'react';
import { NavLink } from 'react-router-dom';

import { useFocusRing } from '@react-aria/focus';
import { PressEvent, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import clsx from 'clsx';

import { NavigationMenuSectionConfig } from '~/apps/chicherin/types';

import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import styles from './index.module.scss';

interface Props
  extends Pick<NavigationMenuSectionConfig, 'label' | 'iconVariant'> {
  /**
   * className applied to the root element
   */
  className?: string;

  /**
   * If passed, renders an item as a link, otherwise just a div wrapper
   */
  linkTo?: string;
  /**
   * Additional props for an item container without link
   */
  containerProps?: React.ComponentProps<'div'>;

  /**
   * If true, renders a second nested level item
   */
  isNestedItem?: boolean;
  /**
   * If true, renders an item with a chevron to use in menu section accordions
   */
  hasChildren?: boolean;
  /**
   * If true, renders an item with menu section open state
   */
  isOpen?: boolean;
  /**
   * If true, renders an item in pressed state for context menu
   */
  isContextMenuOpen?: boolean;
  /**
   * If true, means item has a nested item with active state
   */
  isSomeChildActive?: boolean;

  /**
   * Called, when an item is pressed
   */
  onPress?: (e: PressEvent) => void;
}

export const NavigationMenuItem = React.forwardRef<any, Props>(
  (
    {
      className,

      linkTo,
      containerProps,

      label,
      iconVariant,

      isNestedItem = false,
      hasChildren = false,
      isOpen = false,
      isContextMenuOpen = false,
      isSomeChildActive = false,

      onPress,
    }: Props,
    ref
  ) => {
    const { pressProps, isPressed } = usePress({
      onPress,
    });

    // :focus-visible is not working with usePress correctly, so we use react-aria solution
    const { isFocusVisible, focusProps } = useFocusRing();

    const linkElement = (
      <div className={styles.link}>
        {iconVariant && <Icon variant={iconVariant} />}

        <Typography
          className={clsx(
            styles.hideForCollapsedWithTransition,
            'col-start-2 ellipsis'
          )}
          variant={
            isNestedItem
              ? TypographyVariants.bodyMedium
              : TypographyVariants.bodyMediumStrong
          }
        >
          {label}
        </Typography>

        {hasChildren && (
          <Icon
            {...{
              className: clsx(styles.hideForCollapsed, 'ml-a'),
              variant: IconVariants.chevronDown,
              rotate: isOpen ? RotateVariants.down : RotateVariants.up,
            }}
          />
        )}
      </div>
    );

    return linkTo ? (
      <NavLink
        {...{
          ref,
          to: linkTo,
          className: ({ isActive }) =>
            clsx(className, styles.root, {
              [styles.active]: isActive,
              [styles.pressed]: isPressed || isContextMenuOpen,
              [styles.focused]: isFocusVisible,
            }),
          ...mergeProps(pressProps, focusProps),
        }}
      >
        {linkElement}
      </NavLink>
    ) : (
      <div
        {...{
          ref,
          className: clsx(className, styles.root, {
            [styles.active]: isSomeChildActive && !isOpen,
            [styles.pressed]: isPressed || isContextMenuOpen,
            [styles.focused]: isFocusVisible,
          }),
          tabIndex: 0,
          ...mergeProps(containerProps, pressProps, focusProps),
        }}
      >
        {linkElement}
      </div>
    );
  }
);
