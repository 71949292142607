import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';

import { IconVariants } from '~/shared/components/Icon';

import { NavigationMenuSectionConfig } from './types';

/**
 * Main app navigation menu configuration
 */
export const ARKA_NAVIGATION_MENU_CONFIG: NavigationMenuSectionConfig[] = [
  {
    path: MASLOV_ROUTES_TREE.User.productionCalendarUrl,
    label: 'Календарь',
    iconVariant: IconVariants.calendar,
  },
  {
    path: MASLOV_ROUTES_TREE.User.incidentsUrl,
    label: 'Команды',
    iconVariant: IconVariants.warningCircle,
  },
  {
    path: MASLOV_ROUTES_TREE.User.analyticsUrl,
    label: 'Аналитика',
    iconVariant: IconVariants.analytics,
    children: [
      {
        path: MASLOV_ROUTES_TREE.User.analytics.livestockForecast,
        label: 'Прогноз поголовья',
      },
      {
        path: MASLOV_ROUTES_TREE.User.analytics.herd,
        label: 'Статус стада',
      },
      {
        path: MASLOV_ROUTES_TREE.User.analytics.reproduction,
        label: 'Воспроизводство',
      },
      {
        path: MASLOV_ROUTES_TREE.User.analytics.milking,
        label: 'Доение',
      },
      {
        path: MASLOV_ROUTES_TREE.User.analytics.monitor,
        label: 'Показатели',
      },
      {
        path: MASLOV_ROUTES_TREE.User.analytics.customReports,
        label: 'Отчёты',
      },
    ],
  },
  {
    path: MASLOV_ROUTES_TREE.User.herdUrl,
    label: 'Стадо',
    iconVariant: IconVariants.cow,
    children: [
      {
        path: MASLOV_ROUTES_TREE.User.herd.cows,
        label: 'Животные',
      },
      {
        path: MASLOV_ROUTES_TREE.User.herd.penGroups,
        label: 'Группы животных',
      },
      {
        path: MASLOV_ROUTES_TREE.User.herd.bulls,
        label: 'Генетика',
      },
    ],
  },
  {
    path: MASLOV_ROUTES_TREE.User.entitiesUrl,
    label: 'Управление',
    iconVariant: IconVariants.clipboardList,
    children: [
      {
        path: MASLOV_ROUTES_TREE.User.entities.milkingParlors,
        label: 'Доильные залы',
      },
      {
        path: MASLOV_ROUTES_TREE.User.entities.milkings,
        label: 'Контрольные дойки',
      },
      {
        path: MASLOV_ROUTES_TREE.User.entities.semenDoses,
        label: 'Поставка семени',
      },
    ],
  },
  {
    path: MASLOV_ROUTES_TREE.User.eventsUrl,
    label: 'События',
    iconVariant: IconVariants.clock,
    children: [
      {
        path: MASLOV_ROUTES_TREE.User.events.all,
        label: 'Все события',
      },
      {
        path: MASLOV_ROUTES_TREE.User.events.cowEvents,
        label: 'История событий',
      },
      {
        path: MASLOV_ROUTES_TREE.User.events.commands,
        label: 'История команд',
      },
    ],
  },
];
