import React from 'react';
import { Link } from 'react-router-dom';

import { usePress } from '@react-aria/interactions';
import clsx from 'clsx';
import R from 'ramda';

import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';

import { useArkaNavigation } from '~/services/navigation';

import { MenuItemType } from '../../types';
import styles from './index.module.scss';

interface Props<ItemType extends MenuItemType = MenuItemType> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Item to render
   */
  item: ItemType;
  /**
   * Called, when an item is pressed
   */
  onPress?: (item: ItemType) => void;
  /**
   * If true, the item is highlighted by arrow navigation
   */
  isHighlighted?: boolean;
  /**
   * Additional props getter for implementing navigation
   */
  getItemProps?: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => React.ComponentPropsWithoutRef<'li'>;
}

const MenuItemInternal = <ItemType extends MenuItemType = MenuItemType>(
  {
    className,
    item,
    onPress,
    isHighlighted,
    getItemProps = R.identity as any,
  }: Props<ItemType>,
  ref: React.Ref<HTMLLIElement>
) => {
  const { navigate } = useArkaNavigation();

  const { pressProps } = usePress({
    onPress: () => {
      if (item.isDisabled) return;

      if (item.href) {
        navigate(item.href);
      }
      onPress?.(item);
      item.onPress?.();
    },
  });

  let itemContent;
  if (item.renderContent) {
    itemContent = item.renderContent();
  } else {
    itemContent = item.href ? (
      <Link
        {...{
          className: clsx(styles.item, 'block ellipsis'),
          to: item.href,
          onPointerDown: e => {
            if (e.ctrlKey || e.metaKey) {
              e.stopPropagation();
            }
          },
        }}
      >
        {item.content}
      </Link>
    ) : (
      <div className={clsx(styles.item, 'ellipsis')}>{item.content}</div>
    );
  }

  return (
    <Typography
      {...{
        variant: TypographyVariants.bodySmall,
        className: clsx(
          styles.root,
          className,
          item.isDisabled && styles.disabled,
          isHighlighted && styles.highlighted,
          item.isSelected && styles.selected
        ),
        tag: 'li',
        tabIndex: isHighlighted ? 0 : -1,
        ...mergeProps({ ref }, getItemProps(pressProps)),
      }}
    >
      {itemContent}
    </Typography>
  );
};

// Workaround for typing generic HOC
type RenderMenuItem = <ItemType extends MenuItemType = MenuItemType>(
  props: Props<ItemType>
) => React.ReactElement;

export const MenuItem = React.forwardRef<HTMLLIElement, Props<any>>(
  MenuItemInternal
) as RenderMenuItem;
