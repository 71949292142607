import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';

import { FloatingTree } from '@floating-ui/react';
import { camelCase } from 'camel-case';

import {
  IoCContainerType,
  ServicesContext,
} from '~/~legacy/components/ServicesContext';
import { ErrorBoundaryWrapper } from '~/apps/chicherin/components/ErrorBoundaryWrapper';
import { initIoC } from '~/apps/chicherin/config';

import { Loader } from '~/shared/components/Loader';
import { API_URL } from '~/shared/constants';

import { useAuth } from '~/services/auth';
import { makeApolloClient } from '~/services/gql';
import { ModalRegistrationsDict, ModalsProvider } from '~/services/modals';
import {
  getNotificationPropsFromGQLError,
  NotificationKinds,
  NotificationsContainer,
  NotificationsContext,
  useCreateNotificationsContext,
} from '~/services/notifications';

import layoutStyles from '~/styles/modules/layout.module.scss';

import { AppRoutes } from '../AppRoutes';

// Modals are collected from all project to be registered and be available for reuse anywhere
const context = require.context('../../../../', true, /\.modal\.tsx?$/);

const MODALS_PATHS: string[] = context.keys();

/*
 * Creating a dictionary of modals.
 */
const MODAL_REGISTRATIONS_DICT = MODALS_PATHS.reduce<ModalRegistrationsDict>(
  (acc, path) => {
    const modalName = camelCase(path.split('/').slice(-2, -1).toString());

    const options = context(path).registration;

    return {
      ...acc,
      [modalName]: options,
    };
  },
  {} as ModalRegistrationsDict
);

export const ChicherinApp: React.FC = () => {
  const gqlAuthProvider = useAuth();

  const notificationsContext = useCreateNotificationsContext();

  const [isInitialized, setInitialized] = useState(false);
  const [iocContainer, setIocContainer] = useState<IoCContainerType>({});
  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    const container = initIoC(gqlAuthProvider);
    setIocContainer(container);

    const client = makeApolloClient(API_URL, gqlAuthProvider, error => {
      notificationsContext.sendNotification(
        NotificationKinds.alert,
        getNotificationPropsFromGQLError(error)
      );
    });

    setApolloClient(client);
    gqlAuthProvider.provideApolloClient(client);

    setInitialized(true);
  }, []);

  const loader = <Loader className={layoutStyles.fullHeightContainer} />;

  return isInitialized && apolloClient ? (
    <Suspense fallback={loader}>
      <ServicesContext.Provider value={iocContainer}>
        <ApolloProvider client={apolloClient}>
          <BrowserRouter window={window}>
            <ErrorBoundaryWrapper>
              <FloatingTree>
                <NotificationsContext.Provider value={notificationsContext}>
                  <ModalsProvider
                    modalRegistrationsDict={MODAL_REGISTRATIONS_DICT}
                  >
                    <AppRoutes />
                    <NotificationsContainer />
                  </ModalsProvider>
                </NotificationsContext.Provider>
              </FloatingTree>
            </ErrorBoundaryWrapper>
          </BrowserRouter>
        </ApolloProvider>
      </ServicesContext.Provider>
    </Suspense>
  ) : (
    loader
  );
};
