import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { PenGroupFragmentDoc } from '../fragments/penGroup.graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PenGroupsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']['input']>;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type PenGroupsQuery = { __typename?: 'Query', penGroups: { __typename?: 'PenGroupConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'PenGroupEdge', cursor: string, node: { __typename: 'PenGroup', id: string, name: string, identifier: number, farm: { __typename: 'Farm', id: string } } }> } };


export const PenGroupsDocument = gql`
    query penGroups($after: String, $first: Int, $search: String) {
  penGroups(after: $after, first: $first, search: $search) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...PenGroup
      }
    }
  }
}
    ${PenGroupFragmentDoc}`;

/**
 * __usePenGroupsQuery__
 *
 * To run a query within a React component, call `usePenGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePenGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePenGroupsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      search: // value for 'search'
 *   },
 * });
 */
export function usePenGroupsQuery(baseOptions?: Apollo.QueryHookOptions<PenGroupsQuery, PenGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PenGroupsQuery, PenGroupsQueryVariables>(PenGroupsDocument, options);
      }
export function usePenGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PenGroupsQuery, PenGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PenGroupsQuery, PenGroupsQueryVariables>(PenGroupsDocument, options);
        }
export function usePenGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PenGroupsQuery, PenGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PenGroupsQuery, PenGroupsQueryVariables>(PenGroupsDocument, options);
        }
export type PenGroupsQueryHookResult = ReturnType<typeof usePenGroupsQuery>;
export type PenGroupsLazyQueryHookResult = ReturnType<typeof usePenGroupsLazyQuery>;
export type PenGroupsSuspenseQueryHookResult = ReturnType<typeof usePenGroupsSuspenseQuery>;
export type PenGroupsQueryResult = Apollo.QueryResult<PenGroupsQuery, PenGroupsQueryVariables>;