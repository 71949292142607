import React from 'react';

import clsx from 'clsx';
import R from 'ramda';

import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import {
  Table,
  TableColumnConfig,
  TableThemes,
} from '~/shared/components/Table';
import { TextLink } from '~/shared/components/TextLink';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatDate } from '~/shared/helpers/date';
import { formatInt, formatNumber } from '~/shared/helpers/number';

import { makeDeleteFragmentFromQuery } from '~/services/gql';
import { useConfirm } from '~/services/modals';
import { useArkaNavigation } from '~/services/navigation';
import { useNotifications } from '~/services/notifications';

import { formatCow, getCowIdentifier, getCowPageUrl } from '~/entities/cows';
import { formatPenGroup } from '~/entities/penGroups';
import { TestMilkingFragment } from '~/entities/testMilkings/gql/fragments/testMilking.graphql';

import panelStyles from '~/styles/modules/panel.module.scss';

import { useDeleteTestMilkingMutation } from '../../gql/mutations/deleteTestMilking.graphql';
import { TestMilkingsDocument } from '../../gql/queries/testMilkings.graphql';
import { updateTestMilkingUploadFragment } from '../../helpers';
import { useTestMilkingsPaginatedQuery } from '../../hooks';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Id of test milking upload to load milkings for
   */
  testMilkingUploadID: string;
}

const COW_ID_COLUMN_WIDTH_PX = 164;

export const TestMilkingsTable: React.FC<Props> = ({
  className,
  testMilkingUploadID,
}) => {
  const { sendNeutralToast } = useNotifications();
  const { getPathRelativeToCompany } = useArkaNavigation();

  const [deleteTestMilkingMutation] = useDeleteTestMilkingMutation();
  const confirmDelete = useConfirm();

  const queryVariables = {
    testMilkingUploadIDs: [testMilkingUploadID],
  };

  const { items: testMilkings, ...asyncProps } = useTestMilkingsPaginatedQuery({
    variables: queryVariables,
  });

  const columnConfigs: TableColumnConfig<TestMilkingFragment>[] = [
    {
      title: 'Номер животн.',
      key: 'cowId',
      renderCellContent: ({ cow }) => (
        <TextLink to={getPathRelativeToCompany(getCowPageUrl(cow.id))}>
          {getCowIdentifier(cow)}
        </TextLink>
      ),
      width: COW_ID_COLUMN_WIDTH_PX,
    },
    {
      title: 'Группа, в день КД',
      key: 'penGroup',
      renderCellContent: ({ penGroup }) => formatPenGroup(penGroup),
      width: '1fr',
    },
    {
      title: 'День в доении, в день КД',
      key: 'daysInMilk',
      renderCellContent: ({ daysInMilk }) => formatInt(daysInMilk),
      width: '1fr',
      columnClassName: 'text-right',
    },
    {
      title: 'Объём молока, кг',
      key: 'weightKilograms',
      renderCellContent: ({ weightKilograms }) =>
        formatNumber(weightKilograms, 1),
      width: '1fr',
      columnClassName: 'text-right',
    },
    {
      title: 'Уровень белка, %',
      key: 'proteinPercent',
      renderCellContent: ({ proteinPercent }) => formatNumber(proteinPercent),
      width: '1fr',
      columnClassName: 'text-right',
    },
    {
      title: 'Уровень жира, %',
      key: 'fatPercent',
      renderCellContent: ({ fatPercent }) => formatNumber(fatPercent),
      width: '1fr',
      columnClassName: 'text-right',
    },
    {
      title: 'Уровень соматич. кл., тыс./см³',
      key: 'sccThousandsPerMl',
      renderCellContent: ({ sccThousandsPerMl }) =>
        formatInt(sccThousandsPerMl),
      width: '1fr',
      columnClassName: 'text-right',
    },
    {
      title: 'Мочевина, мг/дл',
      key: 'ureaMgPerDl',
      renderCellContent: ({ ureaMgPerDl }) => formatNumber(ureaMgPerDl, 1),
      width: '1fr',
      columnClassName: 'text-right',
    },
  ];

  const deleteTestMilking = (testMilking: TestMilkingFragment) => {
    confirmDelete({
      title: 'Удаление результатов контрольной дойки коровы',
      message: (
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите удалить результаты контрольную дойки коровы{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {formatCow(testMilking.cow, { withName: false })}
            </Typography>{' '}
            от{' '}
            <Typography variant={TypographyVariants.bodySmallStrong}>
              {formatDate(testMilking.happenedAt)}
            </Typography>{' '}
            ?
          </Typography>
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Это действие невозможно отменить.
          </Typography>
        </div>
      ),
      isDelete: true,
    }).then(isConfirmed => {
      if (!isConfirmed) return;

      deleteTestMilkingMutation({
        variables: {
          id: testMilking.id,
        },
        optimisticResponse: { deleteTestMilking: null },
        update: R.juxt([
          makeDeleteFragmentFromQuery({
            typeName: 'TestMilking',
            query: TestMilkingsDocument,
            variables: queryVariables,
            queryName: 'testMilkings',
          })(testMilking.id),
          updateTestMilkingUploadFragment(testMilkingUploadID, draft => {
            draft.cowsCount -= 1;
          }),
        ]),
      });

      sendNeutralToast('Результаты контрольной дойки коровы удалены');
    });
  };

  return (
    <Table<TestMilkingFragment>
      {...{
        theme: TableThemes.smallSecondary,
        className: clsx(className, panelStyles.panel, 'min-w-full w-min'),
        items: testMilkings,
        columnConfigs,
        withBorder: true,
        withCustomScroll: false,
        noItemsMessage: 'Нет данных для отображения',
        renderItemActions: testMilking => (
          <FunctionButton
            {...{
              iconVariant: IconVariants.trash,
              tooltip: 'Удалить результаты контрольной дойки коровы',
              onPress: () => deleteTestMilking(testMilking),
            }}
          />
        ),
        ...asyncProps,
      }}
    />
  );
};
