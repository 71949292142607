import React from 'react';

import { GraphQLErrors } from '@apollo/client/errors';

import clsx from 'clsx';

import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';

import {
  Callout,
  getNotificationPropsFromGQLError,
  NotificationVariants,
} from '~/services/notifications';

import panelStyles from '~/styles/modules/panel.module.scss';

interface Props {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * Errors occurred during launch
   */
  customReportLaunchErrors: GraphQLErrors;
  /**
   * If true, loader state is displayed
   */
  isLoading?: boolean;
}

export const BlueprintLaunchCard: React.FC<Props> = ({
  className,
  customReportLaunchErrors,
  isLoading,
}) => {
  return (
    <div className={clsx('grid gap-16', className)}>
      {!isLoading &&
        customReportLaunchErrors.map((error, index) => (
          <Callout
            {...{
              key: `${error.message}__${index}`,
              variant: NotificationVariants.error,
              ...getNotificationPropsFromGQLError(error),
            }}
          />
        ))}
      {(!customReportLaunchErrors.length || isLoading) && (
        <div className={clsx(panelStyles.borderedPanel, 'p-24')}>
          <DataBlockedMessage
            {...{
              className: 'p-24',
              isLoading,
              message: 'Пожалуйста, подождите',
            }}
          />
        </div>
      )}
    </div>
  );
};
