import React, { useMemo } from 'react';

import {
  BlueprintLaunchInputValueInput,
  BullRetirementReason,
  CowRetirementReason,
  CowState,
  Dow,
  EventKindEnum,
  LactationState,
  ValueKindEnum,
  VitalityFilter,
} from '@graphql-types';
import R from 'ramda';
import { match } from 'ts-pattern';
import * as yup from 'yup';

import { DateInput } from '~/shared/components/DateInput';
import { DowSelect } from '~/shared/components/DowSelect';
import { Input, InputVariants } from '~/shared/components/Input';
import { Select } from '~/shared/components/Select';
import { formatDateForBackend } from '~/shared/helpers/date';
import { capitalize } from '~/shared/helpers/string';
import { oneOfEnum } from '~/shared/helpers/yup';
import { BaseFieldProps } from '~/shared/types/controls';
import { ValueOf } from '~/shared/types/utility';

import { BullAsyncSelect, BullRetirementReasonSelect } from '~/entities/bulls';
import { CalvingAsyncSelect } from '~/entities/calvings';
import {
  CowAsyncSelect,
  CowRetirementReasonSelect,
  CowStateSelect,
  LactationStateSelect,
} from '~/entities/cows';
import { EmployeeAsyncSelect } from '~/entities/employees';
import { EventAsyncSelect } from '~/entities/events';
import { FarmAsyncSelect } from '~/entities/farms';
import { InseminationAsyncSelect } from '~/entities/inseminations';
import { PenGroupAsyncSelect } from '~/entities/penGroups';
import { SemenDoseAsyncSelect } from '~/entities/semenDoses';

import { BlueprintInputFragment } from '../gql/fragments/blueprintInput.graphql';

const getBlueprintInputValidationsByValueKind = (valueKind: ValueKindEnum) =>
  match(valueKind)
    .with(
      ValueKindEnum.Bool,
      R.always(yup.number().oneOf([0, 1]).required().default(null))
    )
    .with(
      ValueKindEnum.BullId,
      ValueKindEnum.CalvingId,
      ValueKindEnum.CowId,
      ValueKindEnum.DiseaseId,
      ValueKindEnum.EmployeeId,
      ValueKindEnum.EventId,
      ValueKindEnum.FarmId,
      ValueKindEnum.InjectionId,
      ValueKindEnum.InseminationId,
      ValueKindEnum.InseminationSchemeId,
      ValueKindEnum.PenGroupId,
      ValueKindEnum.ProtocolId,
      ValueKindEnum.SemenDoseBatchId,
      ValueKindEnum.UserEventId,
      R.always(yup.string().required())
    ) // ID!
    .with(
      ValueKindEnum.CowIds,
      ValueKindEnum.EventIds,
      R.always(yup.array(yup.string().required()))
    ) // [ID!]
    .with(
      ValueKindEnum.BullRetirementReason,
      R.always(oneOfEnum(BullRetirementReason).required().default(null))
    )
    .with(
      ValueKindEnum.CowRetirementReason,
      R.always(oneOfEnum(CowRetirementReason).required().default(null))
    )
    .with(
      ValueKindEnum.CowState,
      R.always(oneOfEnum(CowState).required().default(null))
    )
    .with(
      ValueKindEnum.LactationState,
      R.always(oneOfEnum(LactationState).required().default(null))
    )
    .with(ValueKindEnum.Date, R.always(yup.string().default('').required())) // Date!
    .with(ValueKindEnum.Dow, R.always(oneOfEnum(Dow).required().default(null)))
    .with(
      ValueKindEnum.Float,
      ValueKindEnum.Int,
      ValueKindEnum.Numeric,
      ValueKindEnum.Index,
      ValueKindEnum.LactationIndex,
      R.always(yup.number().required().default(null))
    )
    .with(ValueKindEnum.Text, R.always(yup.string().required()))
    .with(ValueKindEnum.Void, R.always(yup.string()))
    .exhaustive();

const getBlueprintValueInputByValueKind = (
  valueKind: ValueKindEnum,
  inputValue: ValueOf<BlueprintLaunchInputValueInput>
): BlueprintLaunchInputValueInput =>
  match(valueKind)
    .with(
      ValueKindEnum.BullId,
      ValueKindEnum.CalvingId,
      ValueKindEnum.CowId,
      ValueKindEnum.DiseaseId,
      ValueKindEnum.EmployeeId,
      ValueKindEnum.FarmId,
      ValueKindEnum.InjectionId,
      ValueKindEnum.InseminationId,
      ValueKindEnum.InseminationSchemeId,
      ValueKindEnum.PenGroupId,
      ValueKindEnum.ProtocolId,
      ValueKindEnum.SemenDoseBatchId,
      ValueKindEnum.UserEventId,
      () => ({ idValue: inputValue?.toString() })
    )
    .with(ValueKindEnum.CowIds, ValueKindEnum.EventIds, () =>
      Array.isArray(inputValue) ? { idsValue: inputValue } : {}
    )
    .with(
      // We use shortcodes for default events, so we send EventId as string
      ValueKindEnum.EventId,
      ValueKindEnum.BullRetirementReason,
      ValueKindEnum.CowRetirementReason,
      ValueKindEnum.CowState,
      ValueKindEnum.LactationState,
      ValueKindEnum.Dow,
      ValueKindEnum.Text,
      ValueKindEnum.Void,
      () => ({ strValue: inputValue?.toString() })
    )
    .with(
      ValueKindEnum.Bool,
      ValueKindEnum.Int,
      ValueKindEnum.Index,
      ValueKindEnum.LactationIndex,
      () => (typeof inputValue === 'number' ? { intValue: inputValue } : {})
    )
    .with(ValueKindEnum.Float, ValueKindEnum.Numeric, () =>
      typeof inputValue === 'number'
        ? {
            floatValue: inputValue,
          }
        : {}
    )
    .with(ValueKindEnum.Date, () => ({
      datetimeValue: formatDateForBackend(inputValue?.toString(), true),
    }))
    .exhaustive();

/**
 * Hook for rendering and validating blueprint inputs
 */
export const useBlueprintInputs = (
  blueprintInputsProp: BlueprintInputFragment[] = []
) => {
  // TODO For now we only can work with a single valueKind (and it should always be so)
  // maybe we should rework backend api later
  const blueprintInputs = useMemo(
    () => blueprintInputsProp.filter(input => input.valueKinds.length === 1),
    [blueprintInputsProp]
  );

  const getInputById = (inputId: string) =>
    blueprintInputs.find(input => input.id === inputId);

  const getBlueprintValueInputByInputId = (
    inputId: string,
    inputValue: ValueOf<BlueprintLaunchInputValueInput>
  ) => {
    const input = getInputById(inputId);
    if (!input) {
      return {};
    }

    return getBlueprintValueInputByValueKind(input?.valueKinds[0], inputValue);
  };

  const blueprintFormSchema = useMemo(
    () =>
      yup.object({
        ...Object.fromEntries(
          blueprintInputs.map(input => [
            input.id,
            getBlueprintInputValidationsByValueKind(input.valueKinds[0]),
          ])
        ),
      }),
    [blueprintInputs]
  );

  const renderBlueprintInput = (
    blueprintInput: BlueprintInputFragment,
    additionalProps?: Partial<BaseFieldProps<any>>
  ) => {
    const generalInputProps = {
      name: blueprintInput.id,
      label: capitalize(blueprintInput.prompt || blueprintInput.name),
      ...additionalProps,
    };

    return (
      match(blueprintInput.valueKinds[0])
        // Entities
        .with(ValueKindEnum.DiseaseId, () => (
          <EventAsyncSelect
            {...{
              ...generalInputProps,
              placeholder: 'Выберите болезнь',
              queryOptions: {
                variables: {
                  kinds: [EventKindEnum.Disease],
                },
              },
            }}
          />
        ))
        .with(ValueKindEnum.InjectionId, () => (
          <EventAsyncSelect
            {...{
              ...generalInputProps,
              placeholder: 'Выберите инъекцию',
              queryOptions: {
                variables: {
                  kinds: [EventKindEnum.Injection],
                },
              },
            }}
          />
        ))
        .with(ValueKindEnum.EventId, ValueKindEnum.EventIds, matchedKind => (
          <EventAsyncSelect
            {...{
              ...generalInputProps,
              isMulti: matchedKind === ValueKindEnum.EventIds,
            }}
          />
        ))
        .with(ValueKindEnum.InseminationSchemeId, () => (
          <EventAsyncSelect
            {...{
              ...generalInputProps,
              placeholder: 'Выберите схему осеменения',
              queryOptions: {
                variables: {
                  kinds: [EventKindEnum.InseminationScheme],
                },
              },
            }}
          />
        ))
        .with(ValueKindEnum.ProtocolId, () => (
          <EventAsyncSelect
            {...{
              ...generalInputProps,
              placeholder: 'Выберите протокол',
              queryOptions: {
                variables: {
                  kinds: [EventKindEnum.Protocol],
                },
              },
            }}
          />
        ))
        .with(ValueKindEnum.UserEventId, () => (
          <EventAsyncSelect
            {...{
              ...generalInputProps,
              queryOptions: {
                variables: {
                  kinds: [EventKindEnum.User],
                },
              },
            }}
          />
        ))
        .with(ValueKindEnum.InseminationId, () => (
          <InseminationAsyncSelect {...generalInputProps} />
        ))
        .with(ValueKindEnum.PenGroupId, () => (
          <PenGroupAsyncSelect {...generalInputProps} />
        ))
        .with(ValueKindEnum.SemenDoseBatchId, () => (
          <SemenDoseAsyncSelect {...generalInputProps} />
        ))
        .with(ValueKindEnum.EmployeeId, () => (
          <EmployeeAsyncSelect {...generalInputProps} />
        ))
        .with(ValueKindEnum.CalvingId, () => (
          <CalvingAsyncSelect {...generalInputProps} />
        ))
        .with(ValueKindEnum.BullId, () => (
          <BullAsyncSelect {...generalInputProps} />
        ))
        .with(ValueKindEnum.FarmId, () => (
          <FarmAsyncSelect {...generalInputProps} />
        ))
        .with(ValueKindEnum.CowId, ValueKindEnum.CowIds, matchedKind => (
          <CowAsyncSelect
            {...{
              ...generalInputProps,
              isMulti: matchedKind === ValueKindEnum.CowIds,
              queryOptions: {
                variables: {
                  vitalityFilter: VitalityFilter.Alive,
                },
              },
            }}
          />
        ))

        // Enums
        .with(ValueKindEnum.LactationState, () => (
          <LactationStateSelect {...generalInputProps} />
        ))
        .with(ValueKindEnum.CowRetirementReason, () => (
          <CowRetirementReasonSelect {...generalInputProps} />
        ))
        .with(ValueKindEnum.BullRetirementReason, () => (
          <BullRetirementReasonSelect {...generalInputProps} />
        ))
        .with(ValueKindEnum.Dow, () => <DowSelect {...generalInputProps} />)
        .with(ValueKindEnum.CowState, () => (
          <CowStateSelect {...generalInputProps} />
        ))

        // Basic inputs
        .with(ValueKindEnum.Date, () => <DateInput {...generalInputProps} />)
        .with(ValueKindEnum.Float, ValueKindEnum.Numeric, () => (
          <Input
            {...{
              ...generalInputProps,
              variant: InputVariants.float,
            }}
          />
        ))
        .with(
          ValueKindEnum.Index,
          ValueKindEnum.Int,
          ValueKindEnum.LactationIndex,
          () => (
            <Input
              {...{
                ...generalInputProps,
                variant: InputVariants.int,
              }}
            />
          )
        )
        .with(ValueKindEnum.Text, () => <Input {...generalInputProps} />)
        .with(ValueKindEnum.Bool, () => (
          <Select
            {...{
              ...generalInputProps,
              items: [
                {
                  id: 1,
                  name: 'Да',
                },
                {
                  id: 0,
                  name: 'Нет',
                },
              ],
            }}
          />
        ))
        .with(ValueKindEnum.Void, R.always(null))
        .exhaustive()
    );
  };

  return {
    blueprintInputs,
    getBlueprintValueInputByInputId,
    blueprintFormSchema,
    renderBlueprintInput,
  };
};
