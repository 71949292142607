import React from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';

import { ReactComponent as BrandSvg } from './brand-image.svg';

export const Brand: React.FC<
  Partial<Omit<React.ComponentProps<'a'>, 'ref'>>
> = ({ className, ...props }) => (
  <Link
    {...{
      to: MASLOV_ROUTES_TREE.root,
      className: clsx('flex items-center', className),
      ...props,
    }}
  >
    <BrandSvg />
  </Link>
);
