import { FetchHTTPTransport } from '~/~legacy/services/HttpTransport';
import { StandardServices } from '~/~legacy/types/services';

import { GqlAuthProvider } from '~/services/gql';

export function initIoC(gqlAuthProvider: GqlAuthProvider) {
  const httpTransport = new FetchHTTPTransport(gqlAuthProvider, {
    cache: 'no-cache',
  });

  const container = {
    [StandardServices.Http]: httpTransport,
  };

  return container;
}
