import React from 'react';

import clsx from 'clsx';

import { Badge } from '~/shared/components/Badge';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { Typography, TypographyVariants } from '~/shared/components/Typography';

import { Form } from '~/services/forms';
import { UseModalStepFormInterface } from '~/services/modals';

import { BullAsyncSelect, formatBull } from '~/entities/bulls';

import panelStyles from '~/styles/modules/panel.module.scss';

import { CowsCopyKeyFragment } from '../../../../gql/fragments/CowsCopyKey.graphql';
import { InseminationBullMappingsFormType } from '../../hooks/useInseminationBullMappingsForm';
import stepsStyles from '../../steps.module.scss';
import { MappingItem } from '../MappingItem';

interface Props
  extends Pick<
    UseModalStepFormInterface<InseminationBullMappingsFormType>,
    'formProps'
  > {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * inseminationBulls from CowsCopyKeyFragment
   */
  inseminationBulls: CowsCopyKeyFragment['inseminationBulls'];
}

export const InseminationBullMappingsForm: React.FC<Props> = ({
  className,
  formProps,
  inseminationBulls,
}) => {
  const { formContext } = formProps;

  const inseminationBullMappings = formContext.watch(
    'inseminationBullMappings'
  );

  const hasInseminationBulls = !!inseminationBulls.length;

  return (
    <Form
      {...{
        className,
        ...formProps,
      }}
    >
      <Typography
        {...{
          tag: 'div',
          variant: TypographyVariants.bodyMediumStrong,
          className: 'mb-16',
        }}
      >
        Переданная генетика
      </Typography>
      {hasInseminationBulls && (
        <>
          <Typography
            {...{
              tag: 'div',
              className: stepsStyles.descriptionBlock,
              variant: TypographyVariants.bodySmall,
            }}
          >
            Проверьте список переданных осеменителей на соответствие названиям,
            используемых на вашей ферме. Если соответствия не найдено, создайте
            нового или выберите замену
          </Typography>
          <div className="flex gap-8 mb-12">
            <Typography variant={TypographyVariants.bodySmallStrong}>
              Список осеменителей
            </Typography>
            <Badge isPill>{inseminationBulls?.length ?? 0}</Badge>
          </div>
          <div className="flex flex-col gap-12">
            {inseminationBulls?.map((inseminationBull, index) => {
              const inseminationBullMapping = inseminationBullMappings[index];
              const inseminationBullItemNamePrefix =
                `inseminationBullMappings.${index}` as const;

              return (
                <MappingItem
                  {...{
                    key: inseminationBullMapping.originID,
                    index: index + 1,
                    title: formatBull(inseminationBull, { prefix: '' }),
                    targetValue: inseminationBullMapping,
                    hasIgnoreOption: false,
                    mappingTypeNamePrefix: inseminationBullItemNamePrefix,
                  }}
                >
                  <BullAsyncSelect
                    {...{
                      name: `${inseminationBullItemNamePrefix}.targetID`,
                      placeholder: 'Выберите значение',
                      label: null,
                    }}
                  />
                </MappingItem>
              );
            })}
          </div>
        </>
      )}
      {!hasInseminationBulls && (
        <div className={clsx(panelStyles.largePanel, 'p-24')}>
          <DataBlockedMessage
            {...{
              className: 'p-24',
              message: 'Информация об осеменителях не была передана',
            }}
          />
        </div>
      )}
    </Form>
  );
};
