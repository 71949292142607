import React, { useMemo } from 'react';

import clsx from 'clsx';
import R from 'ramda';
import { match } from 'ts-pattern';

import { AsyncList } from '~/shared/components/AsyncList';
import { DataBlockedMessage } from '~/shared/components/DataBlockedMessage';
import { FunctionButton } from '~/shared/components/FunctionButton';
import { IconVariants } from '~/shared/components/Icon';
import { Skeleton, TextSkeletonSizes } from '~/shared/components/Skeleton';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps, mergeRefs } from '~/shared/helpers/mergeProps';
import { useCustomScrollWrapper } from '~/shared/hooks/useCustomScrollWrapper';

import { TableRow } from './components/TableRow';
import { getSpanValue } from './helpers';
import styles from './index.module.scss';
import { TableColumnConfigInner, TableProps, TableThemes } from './types';

const defaultGetItemKey = <T extends object>(item: T) =>
  'id' in item ? String(item.id) : '';

export const ITEM_ACTIONS_COLUMN_KEY = 'itemActions';

const TableInternal = <T extends object, CellData = undefined>(
  props: TableProps<T, CellData>,
  ref: React.Ref<HTMLTableElement>
) => {
  const {
    className,
    theme = TableThemes.largeSecondary,

    items = [],
    getItemKey = defaultGetItemKey,

    renderItemActions,
    printableTitle,

    columnConfigs,
    isTableWithExpandableRows: isTableWithExpandableRowsProp = false,
    getExpandableRows,
    renderExpandableRowContent,
    hasExpandableRowContent = R.always(true),

    withStickyHeader = true,
    shouldWrapNoItemsMessage = true,
    shouldHideScrollBarsForNoOverflow = true,
    withCustomScroll = true,
    withBorder = false,

    noItemsMessage,
    noSearchItemsMessage,
    noSearchItemsDescription,
    ...asyncListProps
  } = props;
  const hasNestedColumnsLayout = columnConfigs.some(
    config => 'nestedColumns' in config
  );
  const nestedHeaderRowSpan = hasNestedColumnsLayout ? 2 : undefined;

  const flattenColumnConfigs = useMemo(() => {
    return columnConfigs.flatMap<TableColumnConfigInner<T>>(config => {
      if (config.nestedColumns) {
        return config.nestedColumns.map<TableColumnConfigInner<T>>(
          (nestedConfig, configIndex, configsArray) => {
            const isNestedLeft = !configIndex;
            const isNestedRight = configIndex === configsArray.length - 1;
            return {
              ...nestedConfig,
              isNested: true,
              isNestedLeft,
              isNestedRight,
              groupingColumnConfig: config,
            };
          }
        );
      }
      return [
        {
          ...config,
          isNested: false,
          isNestedLeft: false,
          isNestedRight: false,
        },
      ];
    });
  }, [columnConfigs]);

  const isTableWithExpandableRows =
    isTableWithExpandableRowsProp ||
    (!!getExpandableRows && items.some(hasExpandableRowContent)) ||
    !!renderExpandableRowContent;

  if (
    !shouldWrapNoItemsMessage &&
    !items.length &&
    !asyncListProps.isLoading &&
    noItemsMessage
  ) {
    return noItemsMessage;
  }

  const headerTypographyVariant = match(theme)
    .with(TableThemes.tertiary, R.always(TypographyVariants.descriptionLarge))
    .with(
      TableThemes.smallSecondary,
      R.always(TypographyVariants.descriptionMediumStrong)
    )
    .otherwise(R.always(TypographyVariants.descriptionLargeStrong));

  const cellTypographyVariant = match(theme)
    .with(
      TableThemes.smallSecondary,
      R.always(TypographyVariants.descriptionLarge)
    )
    .otherwise(R.always(TypographyVariants.bodySmall));

  const columnsCount =
    flattenColumnConfigs.length +
    (isTableWithExpandableRows ? 1 : 0) +
    (renderItemActions ? 1 : 0);

  let fullHeaderHeightColumnsToSkip = isTableWithExpandableRows ? 1 : 0;

  const renderHeaderColumn = (
    configToRender: TableColumnConfigInner<T>,
    isFirstRow: boolean
  ) => {
    if (
      isFirstRow &&
      configToRender.groupingColumnConfig &&
      !configToRender.isNestedLeft
    ) {
      return null;
    }

    if (
      !isFirstRow &&
      !configToRender.isNested &&
      !configToRender.isSplittedHeader
    ) {
      fullHeaderHeightColumnsToSkip += 1;
      return null;
    }

    const gridColumnStart =
      isFirstRow || !fullHeaderHeightColumnsToSkip
        ? undefined
        : fullHeaderHeightColumnsToSkip + 1;

    if (!isFirstRow) {
      fullHeaderHeightColumnsToSkip = 0;
    }

    const config = isFirstRow
      ? (configToRender.groupingColumnConfig ?? configToRender)
      : configToRender;

    const isGroupingConfig = !!config.nestedColumns;

    const colSpan = config.nestedColumns?.length;
    const rowSpan =
      isFirstRow && !config.nestedColumns && !config.isSplittedHeader
        ? nestedHeaderRowSpan
        : undefined;

    return (
      <th
        {...{
          key: config.key,
          colSpan,
          rowSpan,

          className: clsx(
            styles.headerCell,
            config.columnClassName,
            config.headerClassName,
            {
              [styles.groupedCellLeft]:
                configToRender.isNestedLeft || isGroupingConfig,
              [styles.groupedCellRight]:
                configToRender.isNestedRight || isGroupingConfig,
            }
          ),
          style: {
            gridRow: getSpanValue(rowSpan),
            gridColumnStart,
            gridColumnEnd: getSpanValue(colSpan),
          },
        }}
      >
        <Typography
          {...{
            variant: headerTypographyVariant,
            skeletonSize: TextSkeletonSizes.small,
            ...config.headerTypographyProps,
          }}
        >
          {config.title}
          {!!config.functionButtonProps && (
            <FunctionButton
              {...mergeProps(config.functionButtonProps, {
                className: styles.icon,
              })}
            />
          )}
        </Typography>
      </th>
    );
  };

  const expandableRowColumn = isTableWithExpandableRows
    ? 'var(--expandable-cell-width)'
    : '';
  const isAllColumnWidthsDefinedWithNumbers = !flattenColumnConfigs.some(
    c => typeof c.width !== 'number'
  );
  const otherColumns = flattenColumnConfigs.map(c => {
    if (typeof c.width === 'number') {
      return isAllColumnWidthsDefinedWithNumbers
        ? `minmax(${c.width}px, ${c.width}fr)`
        : `${c.width}px`;
    }
    return c.width ?? 'auto';
  });
  const renderItemActionsColumn = renderItemActions ? 'auto' : '';

  const isSkeletonLoading =
    asyncListProps.isLoading && !asyncListProps.isFetchingMore && !items.length;

  const { useScrollMeasureRef, renderCustomScrollWrapper } =
    useCustomScrollWrapper({
      shouldHideScrollBarsForNoOverflow,
      withPanel: theme !== TableThemes.tertiary,
    });

  const tableElement = (
    <table
      {...{
        ref: mergeRefs(useScrollMeasureRef, ref),
        className: clsx(
          styles.root,
          className,
          withStickyHeader && styles.withStickyHeader,
          theme !== TableThemes.tertiary && styles.withRoundedPanelCorners,
          styles[theme],
          withBorder && styles.withBorder
        ),
        style: {
          gridTemplateColumns: [
            expandableRowColumn,
            ...otherColumns,
            renderItemActionsColumn,
          ].join(' '),
        },
      }}
    >
      <Skeleton isLoading={isSkeletonLoading}>
        <thead data-sticky-element={withStickyHeader} className={styles.thead}>
          {!!printableTitle && (
            <tr className={clsx(styles.headerRow, 'only-for-print')}>
              <th colSpan={columnsCount}>
                <Typography variant={TypographyVariants.heading2}>
                  {printableTitle}
                </Typography>
              </th>
            </tr>
          )}
          <tr className={styles.headerRow}>
            {isTableWithExpandableRows && (
              <th
                {...{
                  className: clsx(styles.headerCell, styles.expandableCell),
                  rowSpan: nestedHeaderRowSpan,
                  style: {
                    gridRow: getSpanValue(nestedHeaderRowSpan),
                  },
                }}
              />
            )}
            {flattenColumnConfigs.map(config =>
              renderHeaderColumn(config, true)
            )}
            {renderItemActions && (
              <th
                {...{
                  className: clsx(styles.headerCell, styles.itemActionCell),
                  key: ITEM_ACTIONS_COLUMN_KEY,
                  rowSpan: nestedHeaderRowSpan,
                  style: {
                    gridRow: getSpanValue(nestedHeaderRowSpan),
                  },
                }}
              />
            )}
          </tr>
          {hasNestedColumnsLayout && (
            <tr className={styles.secondHeaderRow}>
              {flattenColumnConfigs.map(config =>
                renderHeaderColumn(config, false)
              )}
            </tr>
          )}
        </thead>
      </Skeleton>
      <AsyncList<T, false>
        {...{
          ...asyncListProps,
          wrapperTag: 'tbody',
          className: styles.tbody,
          items,
          noItemsMessage: (
            <tr className={styles.row}>
              <td
                {...{
                  className: styles.cell,
                  colSpan: columnsCount,
                  style: {
                    gridColumn: getSpanValue(columnsCount),
                  },
                }}
              >
                <Typography
                  className="col-span-full"
                  variant={cellTypographyVariant}
                >
                  <DataBlockedMessage
                    className={styles.blockedMessage}
                    message={noItemsMessage}
                  />
                </Typography>
              </td>
            </tr>
          ),
          noSearchItemsMessage: (
            <tr className={styles.row}>
              <td
                {...{
                  className: styles.cell,
                  colSpan: columnsCount,
                  style: {
                    gridColumn: getSpanValue(columnsCount),
                  },
                }}
              >
                <Typography
                  className="col-span-full"
                  variant={cellTypographyVariant}
                >
                  <DataBlockedMessage
                    {...{
                      iconVariant: IconVariants.search,
                      className: styles.blockedMessage,
                      message: noSearchItemsMessage,
                      description: noSearchItemsDescription,
                    }}
                  />
                </Typography>
              </td>
            </tr>
          ),
          renderLoader: sentryRef => (
            <tr className={styles.row}>
              <td
                {...{
                  className: styles.cell,
                  colSpan: columnsCount,
                  style: {
                    gridColumn: getSpanValue(columnsCount),
                  },
                }}
              >
                <Typography
                  className="col-span-full"
                  variant={cellTypographyVariant}
                >
                  <DataBlockedMessage
                    {...{
                      className: styles.blockedMessage,
                      isLoading: true,
                      loaderRef: sentryRef,
                      message: 'Загружаем данные таблицы',
                    }}
                  />
                </Typography>
              </td>
            </tr>
          ),
          renderItem: (listItem, rowItemIndex, rowsArray) => {
            return (
              <TableRow
                {...{
                  key: getItemKey(listItem, rowItemIndex),

                  rowItem: listItem,
                  rowItemIndex,
                  rowsArray,

                  isTableWithExpandableRows,

                  flattenColumnConfigs,

                  cellTypographyVariant,

                  tableProps: props,
                }}
              />
            );
          },
        }}
      />
    </table>
  );

  return withCustomScroll
    ? renderCustomScrollWrapper({
        scrollBarKey: columnConfigs.length,
        contentClassName: clsx(
          theme !== TableThemes.tertiary && 'shadow-border'
        ),
        children: tableElement,
      })
    : tableElement;
};

export * from './types';
export * from './constants';

// Workaround for typing generic HOC
type RenderTable = <T extends object, CellData = undefined>(
  props: TableProps<T, CellData>
) => React.ReactElement;

export const Table = React.forwardRef<HTMLTableElement, TableProps<any, any>>(
  TableInternal
) as RenderTable;
