import React from 'react';

import { useFocusRing } from '@react-aria/focus';
import clsx from 'clsx';

import { FunctionButton } from '~/shared/components/FunctionButton';
import { Icon, IconVariants, RotateVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { formatDate } from '~/shared/helpers/date';
import { mergeProps } from '~/shared/helpers/mergeProps';
import { formatShortName } from '~/shared/helpers/nameFormat';
import { useAccordion, UseAccordionProps } from '~/shared/hooks/useAccordion';

import { makeDeleteFragmentFromQuery } from '~/services/gql';
import { useConfirm } from '~/services/modals';
import { useNotifications } from '~/services/notifications';

import panelStyles from '~/styles/modules/panel.module.scss';

import { CowsCopyKeyFragment } from '../../gql/fragments/CowsCopyKey.graphql';
import { useDeleteCowsCopyKeyMutation } from '../../gql/mutations/deleteCowsCopyKey.graphql';
import {
  CowsCopyKeysDocument,
  CowsCopyKeysQueryVariables,
} from '../../gql/queries/CowsCopyKeys.graphql';
import { useFormatMoveToCompanyTitle } from '../../hooks';
import { CopyKeyItem } from '../CopyKeyItem';
import { MoveCowsKeyInfoIcons } from '../MoveCowsKeyInfoIcons';
import { NestedKeyCows } from './components';
import styles from './index.module.scss';

interface Props extends UseAccordionProps {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * CowsCopyKeyFragment item
   */
  cowsCopyKey: CowsCopyKeyFragment;
  /**
   * CowsCopyKey query variables
   */
  queryVariables: CowsCopyKeysQueryVariables;
}

export const MoveCowsKeyAccordion: React.FC<Props> = ({
  className,
  cowsCopyKey,
  queryVariables,
}) => {
  const confirmDelete = useConfirm();
  const { sendNeutralToast } = useNotifications();

  const [deleteCowsCopyKey] = useDeleteCowsCopyKeyMutation();

  const {
    isOpen,
    shouldRenderContent,

    childrenContainerRef,

    openButtonProps,
    childrenWrapperProps,
  } = useAccordion();

  // :focus-visible is not working with usePress correctly, so we use react-aria solution
  const { isFocusVisible, focusProps } = useFocusRing();

  const companyTitle = useFormatMoveToCompanyTitle({
    toCompanyName: cowsCopyKey.toCompanyName ?? cowsCopyKey.toCompany?.name,
    toCompanyInn: cowsCopyKey?.toCompanyInn,
  });

  const hasActivated = !!cowsCopyKey.activatedAt;

  const handleDeleteCowsCopyKey = async () => {
    const isConfirmed = await confirmDelete({
      title: 'Удаление ключа',
      message: (
        <div className="grid gap-12">
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Вы хотите удалить ключ?
          </Typography>
          <div className={styles.deleteKeyInfoWrapper}>
            <Typography variant={TypographyVariants.bodyMediumStrong}>
              {cowsCopyKey.key}
            </Typography>
            <MoveCowsKeyInfoIcons
              {...{
                departDate: formatDate(cowsCopyKey.departDate),
                companyTitle,
                fullName: formatShortName(cowsCopyKey.creator),
                cowsAmount: cowsCopyKey.cows.length,
                isSmall: true,
              }}
            />
          </div>

          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Все карточки животных вернутся к активному состоянию, в статусе,
            который был установлен до перемещения.
          </Typography>
          <Typography tag="p" variant={TypographyVariants.bodySmall}>
            Это действие невозможно отменить.
          </Typography>
        </div>
      ),
      isDelete: true,
    });

    if (!isConfirmed) return;

    deleteCowsCopyKey({
      variables: {
        key: cowsCopyKey.key,
      },
      optimisticResponse: { deleteCowsCopyKey: null },
      update: makeDeleteFragmentFromQuery({
        typeName: 'CowsCopyKey',
        query: CowsCopyKeysDocument,
        queryName: 'cowsCopyKeys',
        variables: queryVariables,
        getIdentifyFields: key => ({ key }),
      })(cowsCopyKey.key),
    });
    sendNeutralToast('Ключ удалён');
  };

  return (
    <div
      className={clsx(
        styles.root,
        panelStyles.largePanel,
        className,
        isOpen && styles.open
      )}
    >
      <div
        {...{
          className: clsx(
            styles.titleWrapper,
            isFocusVisible && styles.focused
          ),
          ...mergeProps(openButtonProps, focusProps),
        }}
      >
        <div
          {...{
            className: 'flex gap-8',
          }}
        >
          <Icon
            {...{
              variant: IconVariants.chevronDown,
              rotate: isOpen ? RotateVariants.up : RotateVariants.left,
            }}
          />
          {hasActivated ? (
            <Typography
              {...{
                className: 'flex gap-8',
                variant: TypographyVariants.bodyMediumStrong,
              }}
            >
              {cowsCopyKey.key}
              <Icon
                {...{
                  color: 'var(--color-fg-soft)',
                  variant: IconVariants.checkCircleFilled,
                }}
              />
            </Typography>
          ) : (
            <CopyKeyItem moveCowsKey={cowsCopyKey.key} />
          )}
        </div>
        <div className="flex gap-16">
          <MoveCowsKeyInfoIcons
            {...{
              cowsAmount: cowsCopyKey.cows.length,
              companyTitle,
              departDate: formatDate(cowsCopyKey.departDate),
              fullName: formatShortName(cowsCopyKey.creator),
              getIconClassName: iconVariant =>
                iconVariant === IconVariants.cow
                  ? styles.cowIcon
                  : styles.moveCowsKeyIcon,
            }}
          />
          <FunctionButton
            {...{
              iconVariant: IconVariants.trash,
              onPress: handleDeleteCowsCopyKey,
              className: styles.hoveredDeleteIcon,
            }}
          />
        </div>
      </div>
      <div
        {...{
          className: styles.children,
          ...childrenWrapperProps,
        }}
      >
        <div ref={childrenContainerRef}>
          {shouldRenderContent && (
            <NestedKeyCows
              {...{
                moveKey: cowsCopyKey.key,
                cows: cowsCopyKey.cows,
                reason: cowsCopyKey.reason,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
