import React from 'react';

import { useMyUserSuspenseQuery } from '../../gql/queries/myUser.graphql';
import { useAuth } from '../../hooks';

export const RequireAuthWrapper: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isAuthenticated } = useAuth();

  // Suspense before we load user info to apply routing logic with companies
  useMyUserSuspenseQuery({
    skip: !isAuthenticated,
  });

  return children;
};
