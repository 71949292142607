import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { CustomReportChartXAxisFragmentDoc } from './customReportChartXAxis.graphql';
import { CustomReportChartYAxisFragmentDoc } from './customReportChartYAxis.graphql';
export type CustomReportChartSettingsFragment = { __typename: 'CustomReportChartSettings', id: string, name: string, kind: Types.CustomReportChartKindEnum, xAxis?: { __typename: 'CustomReportChartValueAxis', valueKey: { __typename: 'CustomReportSettingsValue', formula: Types.CustomReportValueFormulaKindEnum, view: Types.CustomReportValueViewKindEnum, blueprintSourceField: { __typename: 'SourceField', id: string, name: string } } } | { __typename: 'CustomReportChartFieldAxis', blueprintSourceField: { __typename: 'SourceField', id: string, name: string } } | null, yAxes: Array<{ __typename: 'CustomReportChartAggValueAxis', aggFormula?: Types.CustomReportChartAggFormulaKindEnum | null, withRightScale: boolean, valueKey: { __typename: 'CustomReportSettingsValue', formula: Types.CustomReportValueFormulaKindEnum, view: Types.CustomReportValueViewKindEnum, blueprintSourceField: { __typename: 'SourceField', id: string, name: string } } } | { __typename: 'CustomReportChartFieldValueAxis', aggFormula?: Types.CustomReportChartAggFormulaKindEnum | null, withRightScale: boolean, blueprintSourceFieldValue: { __typename: 'CustomReportBlueprintSourceFieldValue', intValue?: number | null, floatValue?: number | null, strValue?: string | null, datetimeValue?: string | null } }> };

export const CustomReportChartSettingsFragmentDoc = gql`
    fragment CustomReportChartSettings on CustomReportChartSettings {
  __typename
  id
  name
  kind
  xAxis {
    ...CustomReportChartXAxis
  }
  yAxes {
    ...CustomReportChartYAxis
  }
}
    ${CustomReportChartXAxisFragmentDoc}
${CustomReportChartYAxisFragmentDoc}`;