import React, { ReactNode } from 'react';

import { useFocusRing } from '@react-aria/focus';
import { PressEvent, usePress } from '@react-aria/interactions';
import clsx from 'clsx';

import { Icon, IconVariants } from '~/shared/components/Icon';
import { Typography, TypographyVariants } from '~/shared/components/Typography';
import { mergeProps } from '~/shared/helpers/mergeProps';

import styles from './index.module.scss';

interface Props extends React.ComponentProps<'div'> {
  /**
   * className applied to the root element
   */
  className?: string;
  /**
   * You can render an icon or an Avatar
   */
  iconElement?: ReactNode;
  /**
   * Label of the select
   */
  label?: ReactNode;
  /**
   * Is select open
   */
  isOpen: boolean;
  /**
   * Called, when a select is pressed
   */
  onPress?: (e: PressEvent) => void;
}

export const NavigationMenuSelect = React.forwardRef<HTMLDivElement, Props>(
  ({ className, iconElement, label, isOpen, onPress, ...other }, ref) => {
    const { pressProps, isPressed } = usePress({
      onPress,
    });

    // :focus-visible is not working with usePress correctly, so we use react-aria solution
    const { isFocusVisible, focusProps } = useFocusRing();

    return (
      <div
        {...{
          ref,
          className: clsx(styles.root, className, {
            [styles.pressed]: isOpen || isPressed,
            [styles.focused]: isFocusVisible,
          }),
          tabIndex: 0,
          ...mergeProps(pressProps, focusProps, other),
        }}
      >
        {iconElement}
        <Typography
          className={clsx(
            'ml-12 ellipsis',
            styles.hideForCollapsedWithTransition
          )}
          variant={TypographyVariants.bodyMediumStrong}
        >
          {label}
        </Typography>
        {label && (
          <Icon
            {...{
              className: clsx('ml-4', styles.hideForCollapsed),
              variant: IconVariants.chevronDown,
            }}
          />
        )}
      </div>
    );
  }
);
