import { Cow, CowState } from '@graphql-types';

import { MASLOV_ROUTES_TREE } from '~/~legacy/constants/maslovRoutesTree';

import { HALF_SPACE, INTERPUNCT, MDASH } from '~/shared/constants';

import {
  getNumberPartFromGlobalId,
  makeReadFragment,
  makeUpdateFragment,
} from '~/services/gql';

import { CowShortFragment } from '~/entities/cows/gql/fragments/cowShort.graphql';

import { CowFragment, CowFragmentDoc } from './gql/fragments/cow.graphql';
import {
  CowDetailedFragment,
  CowDetailedFragmentDoc,
} from './gql/fragments/cowDetailed.graphql';
import { CowFormatOptions } from './types';

/**
 * Returns a url to a single cow page without company id
 */
export const getCowPageUrl = (cowId: string = '') =>
  `${MASLOV_ROUTES_TREE.User.userUrl}cows/${cowId}`;

/**
 * Returns normalized cow identifier
 */
export const getCowIdentifier = (cow?: Pick<Cow, 'identifier' | 'id'> | null) =>
  (cow?.identifier ?? getNumberPartFromGlobalId(cow?.id)).toString();

/**
 * Returns cow identifier formatted for rendering
 */
export const formatCow = (
  cow?: (Pick<Cow, 'identifier' | 'id'> & { name?: string }) | null,
  {
    withNumberSign = true,
    prefix = 'Животное',
    withName = true,
    noCowMessage = MDASH,
  }: CowFormatOptions = {}
) => {
  if (!cow) return noCowMessage;

  const prefixWithSpace = prefix ? `${prefix} ` : '';
  const numberSign = withNumberSign ? `№${HALF_SPACE}` : '';
  const cowName = withName && cow.name ? ` ${INTERPUNCT} ${cow.name}` : '';
  return `${prefixWithSpace}${numberSign}${getCowIdentifier(cow)}${cowName}`;
};

/**
 * Checks, if the cow has bull state
 * Now we have a bull entity, that actually represents only virtual bulls, that we have as semen doses
 * We use bull state as a workaround to represent actual living bulls on the farm
 */
export const isBullCow = (cow?: Pick<Cow, 'state' | 'previousState'> | null) =>
  cow?.state === CowState.Bull || cow?.previousState === CowState.Bull;

/**
 * Checks that the current entity is a cow
 */
export const isCow = <T extends CowShortFragment>(
  cow?: { __typename?: string | undefined } | T | null
): cow is T => cow?.__typename === 'Cow';

/**
 * Update CowDetailed in the cache
 */
export const updateCowDetailedFragment =
  makeUpdateFragment<CowDetailedFragment>({
    typeName: 'Cow',
    fragment: CowDetailedFragmentDoc,
  });

/**
 * Reads cow fragment from cache by id
 */
export const readCowFragment = makeReadFragment<CowFragment>({
  typeName: 'Cow',
  fragment: CowFragmentDoc,
});
