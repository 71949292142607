import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import localeData from 'dayjs/plugin/localeData';

import { getLanguage, initI18n } from '~/~legacy/helpers/i18n';

import { setupCustomYupMethods, setupYupLocale } from '~/shared/helpers/yup';

import { initSentry } from './sentry';

import 'dayjs/locale/ru';

setupYupLocale();
setupCustomYupMethods();

const defaultLanguage = getLanguage();
initI18n(defaultLanguage);

dayjs.extend(localeData);

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

dayjs.extend(customParseFormat);

dayjs.extend(duration);

dayjs.locale('ru');

initSentry();

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}
