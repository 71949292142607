import React from 'react';

import { BlueprintRoleColor } from '@graphql-types';

import { ColoredDot } from '~/shared/components/ColoredDot';
import { ColorShades, getColorCssVarName } from '~/shared/helpers/color';

import { BLUEPRINT_ROLE_COLORS_CONFIGS } from '~/entities/blueprintRoles';

interface Props {
  /**
   * value of BlueprintRoleColor
   */
  value: BlueprintRoleColor;
  /**
   * Should use text with the dot
   */
  withText?: boolean;
}

export const BlueprintRole: React.FC<Props> = ({ value, withText = false }) => {
  const { colorVariant, title } = BLUEPRINT_ROLE_COLORS_CONFIGS[value];

  return (
    <div className="flex gap-8 items-center">
      <ColoredDot
        color={getColorCssVarName(colorVariant, ColorShades.default)}
      />
      {withText && <div>{title}</div>}
    </div>
  );
};
