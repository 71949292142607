import { ColorVariants } from '~/styles/__generated__/token-variants';

/**
 * Each color variant has the same shades, so we can get its name by mixing the variant and the shade
 * @public -- this is a design system contract, so we ignore some unused members for knip
 */
export enum ColorShades {
  default = 'default',
  soft = 'soft',
  muted = 'muted',
  hover = 'hover',
  active = 'active',
  containerDefault = 'container-default',
  containerSoft = 'container-soft',
  containerMuted = 'container-muted',
  containerHover = 'container-hover',
  containerActive = 'container-active',
  opaqueContainerDefault = 'opaque-container-default',
  opaqueContainerSoft = 'opaque-container-soft',
  opaqueContainerMuted = 'opaque-container-muted',
  opaqueContainerHover = 'opaque-container-hover',
  opaqueContainerActive = 'opaque-container-active',
}

/**
 * Gets css variable name of the design system color by its variant and shade
 */
export const getColorCssVarName = (
  variant: ColorVariants,
  shade: ColorShades
) => `var(--color-${variant}-${shade})`;
